import { useContext, useEffect, useRef, useState } from "react";
import classes from "./Patients.module.scss";
import { Context } from "../../../Store/context";
import Button from "../../../components/Button/Button";
import Popup from "../../../components/Popup/Popup";
import { FaPencilAlt } from "react-icons/fa";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint/ComponentToPrint";
import PDFViewer from "./PDFViewer/PDFViewer";
import ExportExcel from "./ExportExcel/ExportExcel";

export default function Patients() {
  const {
    aplications,
    fetchAplications,
    patients,
    fetchPatients,
    sendKit,
    sendResults,
    setResultHiv,
    setResultSyp,
    setMemo,
    setPdfPassword,
    setSampleTakenDate,
    setIsKitCameBack,
    setInspectionDate,
    setCommentHiv,
    setCommentSyp,
    createExcel,
  } = useContext(Context);
  useEffect(() => {
    fetchPatients();
    fetchAplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [PDF, setPDF] = useState(false);
  const componentRef1 = useRef();
  let colsNames = [
    "No",
    "注文日",
    "注文ID",
    "検査者ID",
    "検査者",
    "採取/返却/検査",
    "PDFパス",
    "HIV",
    "梅毒",
    "コメントHIV",
    "コメント梅毒",
    "内部メモ",
    "結果送信",
    "PDFチェック",
  ];
  let colsValueNames = [
    "No",
    "createdAt",
    "aplicationId",
    "id",
    "name",
    "flow",
    "pdfPassword",
    "resultHiv",
    "resultSyp",
    "commentHiv",
    "commentSyp",
    "memo",
    "mailIsSended",
    "pdfCheck",
  ];
  const [popup, setPopup] = useState(false);
  const [settings, setSettings] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(6);
  const [isSended, setIsSended] = useState(false);
  const [mailIsNotSendedOrToday, setMailIsNotSendedOrToday] = useState(true);
  const [order, setOrder] = useState("ASK");
  const [appDate, setAppDate] = useState("");
  const [sampleTakenFilter, setSampleTakenFilter] = useState("");
  const [inspectionDateFilter, setInspectionDateFilter] = useState("");
  const [isKitCameBackFilter, setisKitCameBackFilter] = useState("");
  const [mailIsSendedDateFilter, setmailIsSendedDateFilter] = useState("");
  const [name, setName] = useState("");
  const [printPatients, setPrintPatients] = useState(false);
  const [printDate, setPrintDate] = useState();
  const [patientId, setPatientId] = useState("");
  const onSetSampleTakenDate = (id, date) => {
    setSampleTakenDate(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetIsKitCameBack = (id, date) => {
    setIsKitCameBack(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetInspectionDate = (id, date) => {
    setInspectionDate(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetPdfPassword = (id, password) => {
    setPdfPassword(id, password, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetResultHiv = (id, result, comment) => {
    setResultHiv(id, result, comment, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetResultSyp = (id, result, comment) => {
    setResultSyp(id, result, comment, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetCommentHiv = (id, commentHiv) => {
    setCommentHiv(id, commentHiv, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetCommentSyp = (id, commentSyp) => {
    setCommentSyp(id, commentSyp, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetMemo = (id, memo) => {
    setMemo(id, memo, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSendResults = (id) => {
    sendResults(id, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  //console.log(patients);
  return (
    <div className={classes.aplications}>
      <div className={classes.PrintPatientsByDate}>
        <div
          className={`btn ${classes.btn} ${classes.alt}`}
          onClick={() => {
            setPrintPatients(true);
          }}
        >
          当日検査表
        </div>
      </div>
      <div className={`${classes.tableHeader}`}>
        <div></div>
        <div className={classes.tableTitle}>検査結果</div>
        <div className={classes.filters}>
          <input
            className={classes.idInput}
            type="number"
            placeholder="検査者ID"
            value={patientId}
            onChange={(e) => {
              setPatientId(e.target.value);
              setPage(1);
            }}
          />
          <input
            type="text"
            placeholder="検査者"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setPage(1);
            }}
          />
          <div>
            <label htmlFor="">注文:</label>
            <input
              type="date"
              value={appDate}
              onChange={(e) => {
                setAppDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          {/*<div>
            <label htmlFor="">採取:</label>
            <input
              type="date"
              value={sampleTakenFilter}
              onChange={(e) => {
                setSampleTakenFilter(e.target.value);
                setPage(1);
              }}
            />
            </div>*/}
          <div>
            <label htmlFor="">検査:</label>
            <input
              type="date"
              value={inspectionDateFilter}
              onChange={(e) => {
                setInspectionDateFilter(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div>
            <label htmlFor="">返却:</label>
            <input
              type="date"
              value={isKitCameBackFilter}
              onChange={(e) => {
                setisKitCameBackFilter(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div>
            <label htmlFor="">結果送信:</label>
            <input
              type="date"
              value={mailIsSendedDateFilter}
              onChange={(e) => {
                setmailIsSendedDateFilter(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div>
            <label htmlFor="">未送信</label>
            <input
              type="checkbox"
              checked={mailIsNotSendedOrToday}
              onChange={() => {
                setMailIsNotSendedOrToday(!mailIsNotSendedOrToday);
                setPage(1);
              }}
            />
          </div>
          <select
            name=""
            id=""
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          >
            <option value="ASK">昇順</option>
            <option value="DES">降順</option>
          </select>
          <select
            name=""
            id=""
            value={perPage}
            onChange={(e) => {
              setPerPage(e.target.value);
              setPage(1);
            }}
          >
            <option value="6">6</option>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div
            className={`${classes.btn} ${classes.alt}`}
            onClick={() => {
              setIsSended(false);
              setmailIsSendedDateFilter(false);
              setMailIsNotSendedOrToday(false);
              setIsSended(false);
              setOrder("ASK");
              setAppDate("");
              setSampleTakenFilter("");
              setisKitCameBackFilter("");
              setName("");
              setPatientId("");
              setInspectionDateFilter("");
            }}
          >
            フィルタ解除
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <table>
          <tbody>
            <tr>
              {colsNames?.map((col) => (
                <td key={col} className={`${classes.col}`}>
                  {col}
                </td>
              ))}
              <th></th>
            </tr>
            {[...patients]
              ?.sort((a, b) => (order === "ASK" ? a.id - b.id : b.id - a.id))
              .filter((el) => {
                if (!mailIsNotSendedOrToday) return true;
                let today = new Date().toLocaleDateString();
                let temp = new Date(el.mailIsSended).toLocaleDateString();
                if (!el.mailIsSended || today === temp) return true;
                return false;
              })
              .filter((el) => {
                if (mailIsSendedDateFilter === "") return true;
                let temp = new Date(el.mailIsSended).toLocaleDateString();
                if (new Date(mailIsSendedDateFilter).toLocaleDateString() === temp) return true;
                return false;
              })
              .filter((el) =>
                isKitCameBackFilter === ""
                  ? true
                  : new Date(isKitCameBackFilter).toLocaleDateString() === el.isKitCameBack
              )
              .filter((el) =>
                sampleTakenFilter === ""
                  ? true
                  : new Date(sampleTakenFilter).toLocaleDateString() === el.sampleTakenDate
              )
              .filter((el) =>
                inspectionDateFilter === ""
                  ? true
                  : new Date(inspectionDateFilter).toLocaleDateString() === el.inspectionDate
              )
              .filter((el) => {
                let kitIsSendedDateTime = aplications.filter((app) => el.aplicationId === app.id)[0]
                  ?.kitIsSendedDateTime;
                if (isSended) {
                  if (aplications.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  let today = new Date();
                  let appDate = new Date();
                  appDate.setTime(Date.parse(kitIsSendedDateTime));
                  if (!kitIsSendedDateTime || today.toLocaleDateString() === appDate.toLocaleDateString()) {
                    return true;
                  }
                }
                return true;
              })
              .filter((el) => {
                let tempDate = new Date();
                tempDate.setTime(Date.parse(appDate));
                let appDateTemp = new Date();
                appDateTemp.setTime(Date.parse(el.createdAt));
                if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                  return true;
                }
                return false;
              })
              .filter((el) => el.name && el.name !== "()")
              .filter((el) => (el.name ? el.name?.includes(name) : true))
              .filter((el) => (patientId ? el.id === +patientId : true))
              .slice((page - 1) * perPage, page * perPage)
              .map((patient, i) => {
                let resultsIsSet =
                  patient.product === "HIV"
                    ? patient.resultHiv
                    : patient.product === "梅毒"
                    ? patient.resultSyp
                    : patient.resultHiv && patient.resultSyp;
                let aplication = aplications.filter((app) => patient.aplicationId === app.id)[0];
                return (
                  <tr key={patient.id}>
                    {colsValueNames?.map((col) => {
                      return (
                        <td className={`${classes.col}`} key={col}>
                          {{
                            No: (i + 1).toString(),
                            name: (
                              <div>
                                {patient.name
                                  ?.slice(0, -1)
                                  .split("(")
                                  .reverse()
                                  .map((el, i) => (
                                    <div className={i === 0 ? classes.small : ""} key={+el.length * 50000 + i}>
                                      {el}
                                    </div>
                                  ))}
                              </div>
                            ),
                            createdAt: new Date(patient[col])
                              .toLocaleString()
                              .split(" ")
                              .map((text) => <div key={text}>{text}</div>),
                            updatedAt: new Date(patient[col])
                              .toLocaleString()
                              .split(" ")
                              .map((text) => <div key={text}>{text}</div>),
                            kitIsSendedDateTime: aplication?.kitIsSendedDateTime ? (
                              new Date(aplication?.kitIsSendedDateTime)
                                .toLocaleString()
                                .split(" ")
                                .map((text) => <div key={text}>{text}</div>)
                            ) : (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(aplication.id);
                                  setSettings({
                                    message: "setSended",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: sendKit,
                                    arg: aplication.id,
                                  });
                                }}
                              >
                                Send
                              </div>
                            ),
                            flow: (
                              <div>
                                <div className={classes.dateGroup}>
                                  <div className={classes.lable}>採取:</div>
                                  {patient.sampleTakenDate ? (
                                    <div className={classes.editedField}>
                                      {patient.sampleTakenDate}
                                      <div
                                        className={`${classes.editBtn} ${classes.alt}`}
                                        onClick={() => {
                                          let language = patient.sampleTakenDate.indexOf("/") !== -1 ? 1 : 0;
                                          let temp = language === 1 ? patient.sampleTakenDate.split("/") : undefined;
                                          temp =
                                            language === 1
                                              ? new Date(+temp[0], +temp[1] - 1, +temp[2] + 1)
                                                  .toISOString()
                                                  .split("T")[0]
                                              : undefined;
                                          setPopup(aplication.id);
                                          setSettings({
                                            label: "検体採取日",
                                            value: temp || new Date().toISOString().substring(0, 10),
                                            message: "",
                                            type: "InputDate",
                                            setPopup: setPopup,
                                            fc: onSetSampleTakenDate,
                                            arg: patient.id,
                                          });
                                        }}
                                      >
                                        <FaPencilAlt />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className={`${classes.btn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "採取",
                                          value: patient.sampleTakenDate || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetSampleTakenDate,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      入力
                                    </div>
                                  )}
                                </div>
                                <div className={classes.dateGroup}>
                                  <div className={classes.lable}>返却:</div>
                                  {patient.isKitCameBack ? (
                                    <div className={classes.editedField}>
                                      {patient.isKitCameBack}
                                      <div
                                        className={`${classes.editBtn} ${classes.alt}`}
                                        onClick={() => {
                                          let language = patient.isKitCameBack.indexOf("/") !== -1 ? 1 : 0;
                                          let temp = language === 1 ? patient.isKitCameBack.split("/") : undefined;
                                          temp =
                                            language === 1
                                              ? new Date(+temp[0], +temp[1] - 1, +temp[2] + 1)
                                                  .toISOString()
                                                  .split("T")[0]
                                              : undefined;
                                          setPopup(aplication.id);
                                          setSettings({
                                            label: "キット返却日",
                                            value: temp || new Date().toISOString().substring(0, 10),
                                            message: "",
                                            type: "InputDate",
                                            setPopup: setPopup,
                                            fc: onSetIsKitCameBack,
                                            arg: patient.id,
                                          });
                                        }}
                                      >
                                        <FaPencilAlt />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className={`${classes.btn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "返却",
                                          value: patient.isKitCameBack || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetIsKitCameBack,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      入力
                                    </div>
                                  )}
                                </div>
                                <div className={classes.dateGroup}>
                                  <div className={classes.lable}>検査:</div>
                                  {patient.inspectionDate ? (
                                    <div className={classes.editedField}>
                                      {patient.inspectionDate}
                                      <div
                                        className={`${classes.editBtn} ${classes.alt}`}
                                        onClick={() => {
                                          let language = patient.inspectionDate.indexOf("/") !== -1 ? 1 : 0;
                                          let temp = language === 1 ? patient.inspectionDate.split("/") : undefined;
                                          temp =
                                            language === 1
                                              ? new Date(+temp[0], +temp[1] - 1, +temp[2] + 1)
                                                  .toISOString()
                                                  .split("T")[0]
                                              : undefined;
                                          setPopup(aplication.id);
                                          setSettings({
                                            label: "キット返却日",
                                            value: temp || new Date().toISOString().substring(0, 10),
                                            message: "",
                                            type: "InputDate",
                                            setPopup: setPopup,
                                            fc: onSetInspectionDate,
                                            arg: patient.id,
                                          });
                                        }}
                                      >
                                        <FaPencilAlt />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className={`${classes.btn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "検査",
                                          value: patient.inspectionDate || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetInspectionDate,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      入力
                                    </div>
                                  )}
                                </div>
                              </div>
                            ),
                            pdfPassword: (
                              <div>
                                {patient.pdfPassword ? (
                                  <div className={classes.editedField}>
                                    {patient.pdfPassword}
                                    <div
                                      className={`${classes.editBtn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(patient.id);
                                        setSettings({
                                          value: patient.pdfPassword,
                                          message: "",
                                          type: "input",
                                          setPopup: setPopup,
                                          fc: onSetPdfPassword,
                                          arg: patient.id,
                                          label: "PDFパス",
                                        });
                                      }}
                                    >
                                      <FaPencilAlt />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`${classes.btn} ${classes.alt}`}
                                    onClick={() => {
                                      setPopup(patient.id);
                                      setSettings({
                                        value: patient.pdfPassword,
                                        message: "pdfPassword",
                                        type: "input",
                                        setPopup: setPopup,
                                        fc: onSetPdfPassword,
                                        arg: patient.id,
                                        label: "pdfPassword",
                                      });
                                    }}
                                  >
                                    入力
                                  </div>
                                )}
                              </div>
                            ),
                            resultHiv: patient[col] ? (
                              <div className={classes.editedField}>
                                {patient[col]}
                                <div
                                  className={`${classes.editBtn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(patient.id);
                                    setSettings({
                                      value2: [],
                                      message: "HIV",
                                      type: "setResultHiv",
                                      setPopup: setPopup,
                                      fc: onSetResultHiv,
                                      arg: patient.id,
                                    });
                                  }}
                                >
                                  <FaPencilAlt />
                                </div>
                              </div>
                            ) : aplication?.kitIsSendedDateTime &&
                              patient.isKitCameBack &&
                              patient.sampleTakenDate &&
                              patient.inspectionDate &&
                              (patient.product === "HIV" || patient.product === "HIV・梅毒セット") ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(patient.id);
                                  setSettings({
                                    message: "HIV",
                                    type: "setResultHiv",
                                    setPopup: setPopup,
                                    fc: onSetResultHiv,
                                    arg: patient.id,
                                  });
                                }}
                              >
                                入力
                              </div>
                            ) : (
                              <div />
                            ),
                            resultSyp: patient[col] ? (
                              <div className={classes.editedField}>
                                {patient[col]}
                                <div
                                  className={`${classes.editBtn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(patient.id);
                                    setSettings({
                                      value2: [],
                                      message: "梅毒",
                                      type: "setResultSyp",
                                      setPopup: setPopup,
                                      fc: onSetResultSyp,
                                      arg: patient.id,
                                    });
                                  }}
                                >
                                  <FaPencilAlt />
                                </div>
                              </div>
                            ) : aplication?.kitIsSendedDateTime &&
                              patient.isKitCameBack &&
                              patient.sampleTakenDate &&
                              patient.inspectionDate &&
                              (patient.product === "梅毒" || patient.product === "HIV・梅毒セット") ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(patient.id);
                                  setSettings({
                                    message: "梅毒",
                                    type: "setResultSyp",
                                    setPopup: setPopup,
                                    fc: onSetResultSyp,
                                    arg: patient.id,
                                  });
                                }}
                              >
                                入力
                              </div>
                            ) : (
                              <div />
                            ),
                            commentHiv:
                              patient.product === "HIV" || patient.product === "HIV・梅毒セット" ? (
                                <div className={classes.editedField}>
                                  {/*patient[col]?.length? patient[col].split("\n").map(el=><div>{el}</div>):""*/}
                                  {patient[col]?.length ? patient[col].slice(0, 10) + "..." : ""}
                                  <div
                                    className={`${patient[col] ? classes.editBtn : classes.btn} ${classes.alt}`}
                                    onClick={() => {
                                      setPopup(patient.id);
                                      setSettings({
                                        message: "通知コメントHIV",
                                        type: "textarea",
                                        setPopup: setPopup,
                                        fc: onSetCommentHiv,
                                        arg: patient.id,
                                        label: "",
                                        value: patient.commentHiv,
                                      });
                                    }}
                                  >
                                    {patient[col] ? <FaPencilAlt /> : "入力"}
                                  </div>
                                </div>
                              ) : (
                                <div />
                              ),
                            commentSyp:
                              patient.product === "梅毒" || patient.product === "HIV・梅毒セット" ? (
                                <div className={classes.editedField}>
                                  {/*patient[col]?.length? patient[col].split("\n").map(el=><div>{el}</div>):""*/}
                                  {patient[col]?.length ? patient[col].slice(0, 10) + "..." : ""}
                                  <div
                                    className={`${patient[col] ? classes.editBtn : classes.btn} ${classes.alt}`}
                                    onClick={() => {
                                      setPopup(patient.id);
                                      setSettings({
                                        message: "通知コメント梅毒",
                                        type: "textarea",
                                        setPopup: setPopup,
                                        fc: onSetCommentSyp,
                                        arg: patient.id,
                                        label: "",
                                        value: patient.commentSyp,
                                      });
                                    }}
                                  >
                                    {patient[col] ? <FaPencilAlt /> : "入力"}
                                  </div>
                                </div>
                              ) : (
                                <div />
                              ),
                            memo: (
                              <div className={classes.editedField}>
                                {patient[col]?.length ? patient[col].slice(0, 10) + "..." : ""}
                                <div
                                  className={`${patient[col] ? classes.editBtn : classes.btn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(patient.id);
                                    setSettings({
                                      message: "内部メモ",
                                      type: "textarea",
                                      setPopup: setPopup,
                                      fc: onSetMemo,
                                      arg: patient.id,
                                      label: "",
                                      value: patient.memo,
                                    });
                                  }}
                                >
                                  {patient[col] ? <FaPencilAlt /> : "入力"}
                                </div>
                              </div>
                            ),
                            mailIsSended: patient[col] ? (
                              patient[col].length ? (
                                patient[col].split(" ").map((text) => <div key={text}>{text}</div>)
                              ) : (
                                ""
                              )
                            ) : resultsIsSet ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(patient.id);
                                  setSettings({
                                    message: "結果送信します",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: onSendResults,
                                    arg: patient.id,
                                  });
                                }}
                              >
                                メール送信
                              </div>
                            ) : (
                              <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>メール送信</div>
                            ),
                            pdfCheck: resultsIsSet ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPDF(patient.id);
                                }}
                              >
                                PDFチェック
                              </div>
                            ) : (
                              <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>PDF</div>
                            ),
                          }[col] || patient[col]}
                        </td>
                      );
                    })}
                    <td className={classes.btnField}>
                      <Button
                        className={classes.btn}
                        text={"修正"}
                        link={"/aplication/" + aplication?.id + "?patient=" + patient?.id}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {Math.ceil(
          patients
            .filter((el) => {
              if (mailIsSendedDateFilter === "") return true;
              let temp = new Date(el.mailIsSended).toLocaleDateString("jp");
              if (new Date(mailIsSendedDateFilter).toLocaleDateString("jp") === temp) return true;
              return false;
            })
            .filter((el) =>
              isKitCameBackFilter === ""
                ? true
                : new Date(isKitCameBackFilter).toLocaleDateString() === el.isKitCameBack
            )
            .filter((el) =>
              sampleTakenFilter === "" ? true : new Date(sampleTakenFilter).toLocaleDateString() === el.sampleTakenDate
            )
            .filter((el) =>
              inspectionDateFilter === ""
                ? true
                : new Date(inspectionDateFilter).toLocaleDateString() === el.inspectionDate
            )
            .filter((el) => {
              let kitIsSendedDateTime = patients.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime;
              if (isSended) {
                if (aplications.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime) {
                  return true;
                } else {
                  return false;
                }
              } else {
                let today = new Date();
                let appDate = new Date();
                appDate.setTime(Date.parse(kitIsSendedDateTime));
                if (!kitIsSendedDateTime || today.toLocaleDateString() === appDate.toLocaleDateString()) {
                  return true;
                }
              }
              return true;
            })
            .filter((el) => {
              let tempDate = new Date();
              tempDate.setTime(Date.parse(appDate));
              let appDateTemp = new Date();
              appDateTemp.setTime(Date.parse(el.createdAt));
              if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                return true;
              }
              return false;
            })
            .filter((el) => el.name && el.name !== "()")
            .filter((el) => (el.name ? el.name?.includes(name) : true)).length / perPage
        ) > 1 ? (
          <div className={classes.paginationBtns}>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(page > 1 ? page - 1 : page);
              }}
            >
              perv
            </div>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(
                  page <
                    Math.ceil(
                      patients
                        .filter((el) => {
                          if (mailIsSendedDateFilter === "") return true;
                          let temp = new Date(el.mailIsSended).toLocaleDateString("jp");
                          if (new Date(mailIsSendedDateFilter).toLocaleDateString("jp") === temp) return true;
                          return false;
                        })
                        .filter((el) =>
                          isKitCameBackFilter === ""
                            ? true
                            : new Date(isKitCameBackFilter).toLocaleDateString() === el.isKitCameBack
                        )
                        .filter((el) =>
                          sampleTakenFilter === ""
                            ? true
                            : new Date(sampleTakenFilter).toLocaleDateString() === el.sampleTakenDate
                        )
                        .filter((el) =>
                          inspectionDateFilter === ""
                            ? true
                            : new Date(inspectionDateFilter).toLocaleDateString() === el.inspectionDate
                        )
                        .filter((el) => {
                          let kitIsSendedDateTime = aplications.filter((app) => el.aplicationId === app.id)[0]
                            ?.kitIsSendedDateTime;
                          if (isSended) {
                            if (aplications.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime) {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            let today = new Date();
                            let appDate = new Date();
                            appDate.setTime(Date.parse(kitIsSendedDateTime));
                            if (!kitIsSendedDateTime || today.toLocaleDateString() === appDate.toLocaleDateString()) {
                              return true;
                            }
                          }
                          return true;
                        })
                        .filter((el) => el.name && el.name !== "()")
                        .filter((el) => {
                          let tempDate = new Date();
                          tempDate.setTime(Date.parse(appDate));
                          let appDateTemp = new Date();
                          appDateTemp.setTime(Date.parse(el.createdAt));
                          if (
                            appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() ||
                            appDate.length === 0
                          ) {
                            return true;
                          }
                          return false;
                        })
                        .filter((el) => (el.name ? el.name?.includes(name) : true)).length / perPage
                    )
                    ? page + 1
                    : page
                );
              }}
            >
              next
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {popup ? <Popup settings={settings} /> : ""}
      {printPatients ? (
        <div className={classes.printPopupWrapper}>
          <div className={classes.printPopup}>
            <div
              className={classes.close}
              onClick={() => {
                setPrintDate("");
                setPrintPatients(false);
              }}
            >
              x
            </div>
            <div className={classes.title}>検査日</div>
            <input
              type="date"
              value={printDate}
              onChange={(e) => {
                setPrintDate(e.target.value);
              }}
            />
            {/*<ReactToPrint
              trigger={() => <div className={`btn ${classes.btn} ${classes.alt}`}>印刷</div>}
              content={() => componentRef1.current}
            />*/}
            <ExportExcel
              setPrintPatients={setPrintPatients}
              date={printDate}
              patients={patients.filter((p) => new Date(printDate).toLocaleDateString("ja") === p.inspectionDate)}
            />
            <div className={classes.dn}>
              <ComponentToPrint
                ref={componentRef1}
                printDate={printDate}
                patients={patients.filter((p) => new Date(printDate).toLocaleDateString("ja") === p.inspectionDate)}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {PDF === 0 || PDF ? (
        <div className={classes.PDFpopup}>
          <PDFViewer id={PDF} />{" "}
          <div
            className={`${classes.close} ${classes.btn} ${classes.alt}`}
            onClick={() => {
              setPDF(false);
            }}
          >
            戻る
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
