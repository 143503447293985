import { SET_AUTH, SET_CURRENT_USER, SET_MESSAGE, FETCH_USERS, FETCH_APLICATIONS, FETCH_PATIENTS, FETCH_APLICATION, FETCH_PATIENTS_OF_APLICATION, FETCH_ADDITIONAL_PAYMENTS, FETCH_PDF } from "./types";
const handlers = {
  [SET_AUTH]: (state, { payload }) => ({
    ...state,
    auth: payload,
  }),
  [SET_CURRENT_USER]: (state, { payload }) => ({
    ...state,
    currentUser: payload,
  }),
  [SET_MESSAGE]: (state, { payload }) => ({
    ...state,
    message: payload,
  }),
  [FETCH_USERS]: (state, { payload }) => ({
    ...state,
    users: payload,
  }),
  [FETCH_APLICATIONS]: (state, { payload }) => ({
    ...state,
    aplications: payload,
  }),
  [FETCH_PATIENTS]: (state, { payload }) => ({
    ...state,
    patients: payload,
  }),
  [FETCH_APLICATION]: (state, { payload }) => ({
    ...state,
    currentAplication: payload,
  }),
  [FETCH_ADDITIONAL_PAYMENTS]: (state, { payload }) => ({
    ...state,
    additionalPayments: payload,
  }),
  [FETCH_PDF]: (state, { payload }) => ({
    ...state,
    pdf: payload,
  }),
  DEFAULT: (state) => state,
};

export const reducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  let x = handle(state, action);
  if (action.type === "SET_CURRENT_ADMIN") {
    //console.log(action)
  }
  return x;
};
