import { useState, useContext, useEffect } from "react";
import classes from "./Users.module.scss";
import { Context } from "../../../Store/context";
import Button from "../../../components/Button/Button";

export default function Users() {
  const { users, fetchUsers, disableEnable } = useContext(Context);
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let colsNames = ["No", "名前", "ユーザーID", "権限", "createdAt", "updatedAt"];
  let colsValueNames = ["id", "name", "userId", "role", "createdAt", "updatedAt"];
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  const [role, setRole] = useState("");
  const [order, setOrder] = useState("ASK");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [length, setLength] = useState(10);
  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };
  const disableEnableUser = ({id, active}) => {
    disableEnable(id, active);
  };
  return (
    <div className={classes.users}>
      <div className={`${classes.TableHeader}`}>
        <div className={classes.left}>
          <Button text={"追加"} link={"/users/add"} />
        </div>
        <div className={classes.center}>ユーザー</div>
        <div className={classes.right}>
          <div className={classes.filters}>
            <input value={search} type={"text"} placeholder={"名前/ユーザーID"} onChange={onChangeSearch} />
            <select
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
                setPage(1);
              }}
            >
              <option value="">role</option>
              <option value="1">管理者</option>
              <option value="2">スタッフ</option>
            </select>
            <select
              value={active}
              onChange={(e) => {
                setActive(e.target.value);
                setPage(1);
              }}
            >
              <option value="">activity</option>
              <option value="active">active</option>
              <option value="inActive">inActive</option>
            </select>
            <select
              value={order}
              onChange={(e) => {
                setOrder(e.target.value);
              }}
            >
              <option value="ASK">ASK</option>
              <option value="DES">DES</option>
            </select>
            <select
              value={perPage}
              onChange={(e) => {
                setPerPage(e.target.value);
                setPage(1);
              }}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <table>
          <tbody>
            <tr>
              {colsNames?.map((col, i) => (
                <td key={col + i} className={`${classes.col}`}>
                  {col}
                </td>
              ))}
              <th></th>
              {users ? <th></th> : ""}
            </tr>
            {[...users]
              ?.sort((a, b) => (order === "ASK" ? a.id - b.id : b.id - a.id))
              .filter((el) => role === "" || el.role.toString() === role)
              .filter(
                (el) => active === "" || (el.active && active === "active") || (!el.active && active === "inActive")
              )
              .filter((el) => {
                return (
                  el.name.toString().toLowerCase().includes(search?.toLowerCase()) ||
                  el.userId.toString().toLowerCase().includes(search?.toLowerCase())
                );
              })
              .slice((page - 1) * perPage, page * perPage)
              .map((user, i) => {
                return (
                  <tr key={i}>
                    {colsValueNames?.map((col) => {
                      return (
                        <td className={`${classes.col}`} key={col}>
                          {{
                            No: (i + 1).toString(),
                            createdAt: new Date(user[col]).toLocaleString(),
                            updatedAt: new Date(user[col]).toLocaleString(),
                            active: user[col] ? "" : "無効",
                            role: user[col] === 1 ? "管理者" : "スタッフ",
                          }[col] || user[col]}
                        </td>
                      );
                    })}
                    <td className={classes.btnField}>
                      <Button className={classes.btn} text={"修正"} link={"/users/user/" + user.id} />
                    </td>
                    <td className={classes.btnField}>
                      <Button
                        text={`${user.active ? "無効" : "有効"}`}
                        fc={disableEnableUser}
                        fcArg={{ id: user.id, active: user.active }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {Math.ceil(
          users
            .filter((el) => role === "" || el.role.toString() === role)
            .filter(
              (el) => active === "" || (el.active && active === "active") || (!el.active && active === "inActive")
            )
            .filter((el) => {
              return (
                el.name.toString().toLowerCase().includes(search?.toLowerCase()) ||
                el.userId.toString().toLowerCase().includes(search?.toLowerCase())
              );
            }).length / perPage
        ) > 1 ? (
          <div className={classes.paginationBtns}>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(page > 1 ? page - 1 : page);
              }}
            >
              perv
            </div>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(
                  page <
                    Math.ceil(
                      users
                        .filter((el) => role === "" || el.role.toString() === role)
                        .filter(
                          (el) =>
                            active === "" || (el.active && active === "active") || (!el.active && active === "inActive")
                        )
                        .filter((el) => {
                          return (
                            el.name.toString().toLowerCase().includes(search?.toLowerCase()) ||
                            el.userId.toString().toLowerCase().includes(search?.toLowerCase())
                          );
                        }).length / perPage
                    )
                    ? page + 1
                    : page
                );
              }}
            >
              next
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
