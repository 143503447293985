import { useState } from "react";
import classes from "./Popup.module.scss";

export default function Popup({ settings }) {
  const onOk = () => {
    settings.setPopup(false);
    if (settings.arg) {
      settings.fc(settings.arg);
    } else {
      settings.fc();
    }
  };
  const onInputOk = () => {
    settings.setPopup(false);
    settings.fc(settings.arg, inputData, inputData2);
  };
  const onClose = () => {
    settings.setPopup(false);
  };
  let hivComments = ["陰性(－)", "HIV陽性(＋)", "血液半量", "血液少量", "検体古い", "血液抽出不可", "判定保留"];
  let sypComments = [
    "陰性(－)",
    "梅毒陽性(＋)：TPのみ陽性",
    "梅毒陽性(＋)：TP及びRPR陽性",
    "血液半量",
    "血液少量",
    "検体古い",
    "血液抽出不可",
    "判定保留",
  ];
  const [inputData, setInputData] = useState(settings.value);
  const [inputData2, setInputData2] = useState(settings.value2 || []);
  const onAddComent = (comment) => {
    let temp = inputData2?.findIndex((el) => el === comment);
    if (temp !== -1) {
      inputData2.splice(temp, 1);
    } else {
      inputData2.push(comment);
    }
    setInputData2([...inputData2]);
  };
  //console.log(inputData2);
  return (
    <div className={classes.wrapper}>
      <div className={`${classes.popup}`}>
        <div className={` ${settings.message.type === "error" ? classes.red : ""} ${classes.title}`}>
          {settings.message}
        </div>
        {
          {
            info: (
              <div className={`btn`} onClick={onClose}>
                OK
              </div>
            ),
            "Yes/Cancel": (
              <div className={classes.btns}>
                <div className={`btn ${classes.btn}`} onClick={onOk}>
                  OK
                </div>
                <div className={`btn ${classes.btn}`} onClick={onClose}>
                  Cancel
                </div>
              </div>
            ),
            InputDate: (
              <div>
                <div className={classes.btns}>
                  <label htmlFor="">{settings.label}</label>
                  <input type="date" value={inputData} onChange={(e) => setInputData(e.target.value)} />
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </div>
            ),
            setResultHiv: (
              <>
                <div className={classes.btns}>
                  <label>結果</label>
                  <select onChange={(e) => setInputData(e.target.value)}>
                    <option value=""></option>
                    <option value="陰性">陰性</option>
                    <option value="陽性">陽性</option>
                    <option value="判定保留">判定保留</option>
                    <option value="検査不可">検査不可</option>
                  </select>
                </div>
                <div className={classes.comment}>
                  <label>コメントHIV</label>
                </div>
                <div className={`${classes.btns} ${classes.comments}`}>
                  {hivComments.map((comment) => (
                    <div>
                      <input
                        id={comment}
                        type="checkbox"
                        onClick={() => {
                          onAddComent(comment);
                        }}
                      />
                      <label htmlFor={comment}>{comment}</label>
                    </div>
                  ))}
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
            setResultSyp: (
              <>
                <div className={classes.btns}>
                  <label>結果</label>
                  <select onChange={(e) => setInputData(e.target.value)}>
                    <option value=""></option>
                    <option value="陰性">陰性</option>
                    <option value="陽性">陽性</option>
                    <option value="判定保留">判定保留</option>
                    <option value="検査不可">検査不可</option>
                  </select>
                </div>
                <div className={classes.comment}>
                  <label>コメント梅毒</label>
                </div>
                <div className={classes.btns}>
                  <div className={`${classes.btns} ${classes.comments}`}>
                    {sypComments.map((comment) => (
                      <div>
                        <input
                          id={comment}
                          type="checkbox"
                          onClick={() => {
                            onAddComent(comment);
                          }}
                        />
                        <label htmlFor={comment}>{comment}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
            setFrom: (
              <>
                <div className={classes.btns}>
                  <label>荷物差出人名</label>
                  <select value={inputData} onChange={(e) => setInputData(e.target.value)}>
                    <option value="株トリエル">株トリエル</option>
                    <option value="トリエル太郎">トリエル太郎</option>
                  </select>
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
            input: (
              <>
                <div className={classes.btns}>
                  <label>{settings.label}</label>
                  <input
                    type={`${settings.inputType || "text"}`}
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                  />
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
            name: (
              <>
                <div className={classes.btns}>
                  <label>{settings.label}</label>
                  <input
                    type={`${settings.inputType || "text"}`}
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                  />
                </div>
                <div className={classes.btns}>
                  <label>{settings.label2}</label>
                  <input
                    type={`${settings.inputType || "text"}`}
                    value={inputData2}
                    onChange={(e) => setInputData2(e.target.value)}
                  />
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
            textarea: (
              <>
                <div className={classes.btns}>
                  <label>{settings.label}</label>
                  <textarea
                    rows="5"
                    cols="33"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                  ></textarea>
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
            gender: (
              <>
                <div className={classes.btns}>
                  <label>{settings.label}</label>
                  <select value={inputData} onChange={(e) => setInputData(e.target.value)}>
                    <option value="男性">男性</option>
                    <option value="女性">女性</option>
                    <option value="その他">その他</option>
                  </select>
                </div>
                <div className={classes.btns}>
                  <div className={`btn ${classes.btn}`} onClick={onInputOk}>
                    OK
                  </div>
                  <div className={`btn ${classes.btn}`} onClick={onClose}>
                    Cancel
                  </div>
                </div>
              </>
            ),
          }[settings.type]
        }
      </div>
    </div>
  );
}
