import * as FileSever from "file-saver";
import XLSX from "sheetjs-style";
import classes from "./ExportExcel.module.scss";

const ExportExcel = ({ date, patients,setPrintPatients }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const ext = ".xlsx";
  const exportToExcel = async () => {
    const workSheet = new XLSX.utils.json_to_sheet([]);
    console.log(workSheet);
    workSheet["A1"] = { v: "検査日：" + date.toString() };
    workSheet["E1"] = { v: "当日検査表" , s: { font: { sz: 20 } } };
    workSheet[`A${3}`] = { v: ""};
    workSheet[`B${3}`] = { v: "ID" };
    workSheet[`C${3}`] = { v: "氏名" };
    workSheet[`D${3}`] = { v: "採取日" };
    workSheet[`E${3}`] = { v: "受付日" };
    workSheet[`F${3}`] = { v: "報告日" };
    workSheet[`G${3}`] = { v: "HIV" };
    workSheet[`H${3}`] = { v: "梅毒" };
    workSheet[`I${3}`] = { v: "特記事項" };
    patients.forEach((p, i) => {
      workSheet[`A${i + 4}`] = { v: i + 1 };
      workSheet[`B${i + 4}`] = { v: p.id };
      workSheet[`C${i + 4}`] = { v: p.name };
      workSheet[`D${i + 4}`] = { v: p.sampleTakenDate };
      workSheet[`E${i + 4}`] = { v: p.isKitCameBack };
      workSheet[`F${i + 4}`] = { v: "" };
      workSheet[`G${i + 4}`] = { v: p.product !== "梅毒" ? "" : "／" };
      workSheet[`H${i + 4}`] = { v: p.product !== "HIV" ? "" : "／" };
      workSheet[`I${i + 4}`] = { v: p.memo||"" };
    });
    let data = patients.map((p) => {
      return {
        "ID": p.id,
        氏名: p.name,
        採取日: p.sampleTakenDate,
        受付日: p.isKitCameBack,
        報告日: "",
        HIV: p.product !== "梅毒" ? "" : "／",
        梅毒: p.product !== "HIV" ? "" : "／",
        特記事項: p.memo||"",
      };
    });
    workSheet["!cols"] = [
      {
        width: "4",
      },
      {
        width: "6",
      },
      {
        width: "36",
      },
      {
        width: "10",
      },
      {
        width: "10",
      },
      {
        width: "10",
      },
      {
        width: "6",
      },
      {
        width: "6",
      },
      {
        width: "32",
      },
    ];
    workSheet["!rows"] = [];
    for (let i = 0; i < patients.length + 3; i++) {
      if (i < 3) {
        workSheet["!rows"].push({});
      } else {
        workSheet["!rows"].push({ hpt: "25" });
      }
    }
    for (let key in workSheet) {
      if (key !== "!ref" && key !== "!cols" && key !== "!rows" && key !== "A1" && key !== "E1") {
        workSheet[key].s = {
          alignment: { vertical: "center", horizontal: "center", wrapText: "true" },
          border: {
            top: { style: "medium", color: { rgb: "FF000000" } },
            bottom: { style: "medium", color: { rgb: "FF000000" } },
            left: { style: "medium", color: { rgb: "FF000000" } },
            right: { style: "medium", color: { rgb: "FF000000" } },
          },
        };
      }
      if(key.includes("I") && key !== "I3"){
        workSheet[key].s = {...workSheet[key].s,
          alignment: { vertical: "top", horizontal: "left", wrapText: "true" },
          font:{sz:8}
        };
      }
    }
    workSheet["!ref"] = `A1:I${patients.length + 4}`;
    console.log(workSheet);
    const workBook = { Sheets: { [date.toString()]: workSheet }, SheetNames: [date.toString()] };
    const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    data = new Blob([buffer], { type: fileType });
    FileSever.saveAs(data, "当日検査表_" + date.toString() + ext);
    setPrintPatients(false)
  };
  return (
    <div className={`btn ${classes.btn} ${classes.alt}`} onClick={exportToExcel}>
      Excel
    </div>
  );
};

export default ExportExcel;
