import { useContext } from "react";
import classes from "./Message.module.scss";
import { Context } from "../../Store/context";

export default function Message() {
  const { message, setMessage} = useContext(Context);
  return (
    <div className={classes.wrapper}>
      <div className={`${classes.popup}`}>
        <div className={` ${message.type === "error" ? classes.red : ""}`}>{message.text}</div>
        {message.type === "error" ? (
          <div
            className={`btn`}
            onClick={() => {
              setMessage({});
            }}
          >
            OK
          </div>
        ) : (
          <div className={`btn`}></div>
        )}
      </div>
    </div>
  );
}
