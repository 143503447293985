import { useState, useContext, useEffect } from "react";
import classes from "./EditAplication.module.scss";
import { Context } from "../../../../Store/context";
import { useParams, useSearchParams } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import Popup from "../../../../components/Popup/Popup";
import SendAddressPopup from "./SendAddressPopup/SendAddressPopup";
import { FaPencilAlt } from "react-icons/fa";

export default function EditAplication() {
  const {
    currentAplication,
    fetchCurrentAplication,
    setResultHiv,
    setResultSyp,
    setFrom,
    setAddress,
    setPaid,
    sendKit,
    sendResults,
    setPostAddress,
    setCommentHiv,
    setCommentSyp,
    setMemo,
    setPdfPassword,
    setName,
    setEmail,
    setGender,
    setBirthdate,
    setPhone,
    setPatientName,
    setPatientGender,
    setPatientBirthdate,
  } = useContext(Context);
  const params = useParams();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    fetchCurrentAplication(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setAplication(currentAplication);
  }, [currentAplication]);
  const [aplication, setAplication] = useState(currentAplication);
  const [popup, setPopup] = useState(false);
  const [wherePopup, setWherePopup] = useState(false);
  const [settings, setSettings] = useState({});
  const onSetResultHiv = (id, result, comment) => {
    setResultHiv(id, result, comment, aplication.id);
  };
  const onSetResultSyp = (id, result, comment) => {
    setResultSyp(id, result, comment, aplication.id);
  };
  const onSetPdfPassword = (id, password) => {
    setPdfPassword(id, password, aplication.id);
  };
  const onSetCommentHiv = (id, commentHiv) => {
    setCommentHiv(id, commentHiv, aplication.id);
  };
  const onSetCommentSyp = (id, commentSyp) => {
    setCommentSyp(id, commentSyp, aplication.id);
  };
  const onSetMemo = (id, memo) => {
    setMemo(id, memo, aplication.id);
  };
  const onSendResults = (id) => {
    sendResults(id, aplication.id);
  };
  const onSetFrom = (id, postFrom) => {
    setFrom(id, postFrom);
  };
  const onSetName = (id, name) => {
    setName(id, name);
  };
  const onSetEmail = (id, email) => {
    setEmail(id, email);
  };
  const onSetGender = (id, gender) => {
    setGender(id, gender);
  };
  const onSetBirthdate = (id, birthdate) => {
    let array = birthdate.split("-");
    birthdate = "";
    array.forEach((el, i) => {
      birthdate = birthdate + +el + (i === 0 ? "年" : i === 1 ? "月" : "日");
    });
    setBirthdate(id, birthdate);
  };
  const onSetPhone = (id, phone) => {
    setPhone(id, phone);
  };
  const onSetAddress = (id, address) => {
    setAddress(id, address);
  };
  const onSetPostAddress = (postWhere, postWhereName, postWhereAddress) => {
    setPostAddress(currentAplication.id, postWhere, postWhereName, postWhereAddress);
  };
  const onSetPatientName = (id, name, furigana) => {
    name = name + "("+furigana+")"
    setPatientName(id, name, aplication.id);
  };
  const onSetPatientGender = (id, gender) => {
    setPatientGender(id, gender, aplication.id);
  };
  const onSetPatientBirthdate = (id, birthdate) => {
    let array = birthdate.split("-");
    birthdate = "";
    array.forEach((el, i) => {
      birthdate = birthdate + +el + (i === 0 ? "年" : i === 1 ? "月" : "日");
    });
    setPatientBirthdate(id, birthdate, aplication.id);
  };
  const [printBtnDisable, setPrintBtnDisable] = useState(false);
  const sealPrint = (patient) => {
    setPrintBtnDisable(true);
    setTimeout(() => {
      setPrintBtnDisable(false);
    }, 3000);
    let testDateTime = new Date();
    patient = {
      ...patient,
      testDate: testDateTime.toLocaleDateString(),
      testTime: testDateTime.toLocaleTimeString().slice(0, -3),
    };
    //console.log(patient);
    window.DoPrint(patient);
  };
  return (
    <div className={classes.editAplication}>
      <div className={classes.left}>
        <div className={classes.title}>{params.id ? "修正：" : "Create"}</div>
        <form className={classes.form}>
          <div className={classes.formGroup}>
            <label>注文日</label>
            <div>{new Date(aplication?.createdAt).toLocaleString()}</div>
          </div>
          <div className={classes.formGroup}>
            <label>注文ID</label>
            <div>{aplication?.id}</div>
          </div>
          <div className={classes.formGroup}>
            <label>申込者</label>
            <div className={classes.editDiv}>
              <div>{aplication.name}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: aplication.name,
                    message: "申込者",
                    type: "input",
                    setPopup: setPopup,
                    fc: onSetName,
                    arg: aplication.id,
                    label: "申込者:",
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>Eメール</label>
            <div className={classes.editDiv}>
              <div>{aplication.email}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: aplication.email,
                    message: "Eメール",
                    type: "input",
                    setPopup: setPopup,
                    fc: onSetEmail,
                    arg: aplication.id,
                    label: "Eメール:",
                    inputType: "email",
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>性別</label>
            <div className={classes.editDiv}>
              <div>{aplication.gender}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: aplication.gender,
                    message: "性別",
                    type: "gender",
                    setPopup: setPopup,
                    fc: onSetGender,
                    arg: aplication.id,
                    label: "性別:",
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>生年月日</label>
            <div className={classes.editDiv}>
              <div>{aplication.birthdate}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: () => {
                      let date = new Date(aplication.birthdate.replace("年", "-").replace("月", "-").slice(0, -1));
                      let year = date.getFullYear();
                      let month = (date.getMonth() + 1).toString().padStart(2, "0");
                      let day = date.getDate().toString().padStart(2, "0");
                      return `${year}-${month}-${day}`;
                    },
                    message: "生年月日",
                    type: "input",
                    setPopup: setPopup,
                    fc: onSetBirthdate,
                    arg: aplication.id,
                    label: "生年月日:",
                    inputType: "date",
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>電話</label>
            <div className={classes.editDiv}>
              <div>{aplication.phone}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: aplication.phone,
                    message: "電話",
                    type: "input",
                    setPopup: setPopup,
                    fc: onSetPhone,
                    arg: aplication.id,
                    label: "電話:",
                    inputType: "number",
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>住所</label>
            <div className={classes.editDiv}>
              <div>{aplication.address}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: aplication.address,
                    message: "",
                    type: "input",
                    setPopup: setPopup,
                    fc: onSetAddress,
                    arg: aplication.id,
                    label: "住所",
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>配送先選択</label>
            <div className={classes.editDiv}>
              <div>{aplication.postWhere}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setWherePopup(aplication.id);
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
          </div>
          {currentAplication.postWhere === "郵便局留め" || currentAplication.postWhere === "ヤマトセンター止め" ? (
            <>
              <div className={classes.formGroup}>
                <label>
                  <span className={`${classes.requireInput} ${classes.withLinks}`} id="配送先名を">
                    {currentAplication.postWhere === "郵便局留め" ? (
                      <div>
                        郵便局名 <br />
                        <a target="_blank" rel="noreferrer" href="https://map.japanpost.jp/p/search/">
                          《郵便局検索》
                        </a>
                      </div>
                    ) : (
                      <div>
                        センター名 <br />
                        <a target="_blank" rel="noreferrer" href="http://www.e-map.ne.jp/p/yamato01/">
                          《センター検索》
                        </a>
                      </div>
                    )}
                  </span>
                </label>
                <div>{currentAplication.postWhereName}</div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className={classes.formGroup}>
            <label>
              <span className={classes.requireInput} id="配送先住所を">
                {currentAplication.postWhere === "郵便局留め"
                  ? "郵便局住所"
                  : currentAplication.postWhere === "郵便局留め"
                  ? "センター住所"
                  : "届け先"}
              </span>
            </label>
            <div>{currentAplication.postWhereAddress}</div>
          </div>
          {/*<div className={classes.formGroup}>
            <label>荷物差出人名</label>
            <div className={classes.editDiv}>
              <div>{aplication.postFrom}</div>
              <div
                className={`${classes.editBtn} ${classes.alt}`}
                onClick={() => {
                  setPopup(aplication.id);
                  setSettings({
                    value: aplication.postFrom,
                    message: "",
                    type: "setFrom",
                    setPopup: setPopup,
                    fc: onSetFrom,
                    arg: aplication.id,
                  });
                }}
              >
                <FaPencilAlt />
              </div>
            </div>
              </div>*/}
          <div className={classes.formGroup}>
            <label>支払い方法</label>
            <div>{currentAplication.payMethod}</div>
          </div>
          <div className={classes.formGroup}>
            <label>支払済</label>
            <div>
              {currentAplication.isPaid ? (
                "支払い済み"
              ) : (
                <div className={classes.editDiv}>
                  未
                  <div
                    className={`${classes.editBtn} ${classes.alt}`}
                    onClick={() => {
                      setPopup(aplication.id);
                      setSettings({
                        message: "支払済みにしますか？",
                        type: "Yes/Cancel",
                        setPopup: setPopup,
                        fc: setPaid,
                        arg: aplication.id,
                      });
                    }}
                  >
                    <FaPencilAlt />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.formGroup}>
            <label>発送処理日</label>
            <div>
              {currentAplication.kitIsSendedDateTime ? (
                new Date(currentAplication.kitIsSendedDateTime).toLocaleString()
              ) : (
                <div className={classes.editDiv}>
                  未
                  <div
                    className={`${classes.btn} ${classes.alt}`}
                    onClick={() => {
                      setPopup(aplication.id);
                      setSettings({
                        message: "発送済みにしますか？",
                        type: "Yes/Cancel",
                        setPopup: setPopup,
                        fc: sendKit,
                        arg: aplication.id,
                      });
                    }}
                  >
                    発送処理
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*<div className={classes.formGroup}>
            <label>到着予定日時</label>
            <div>
              {currentAplication.estimatedArrivalDateTime ? (
                new Date(currentAplication.estimatedArrivalDateTime).toLocaleDateString()
              ) : (
                <div className={classes.editDiv}>
                  not set
                  {currentAplication.kitIsSendedDateTime ? (
                    <div
                      className={`${classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(aplication.id);
                        setSettings({
                          message: "estimatedArrivalDateTime",
                          type: "InputDate",
                          setPopup: setPopup,
                          fc: setArrivalDate,
                          arg: aplication.id,
                        });
                      }}
                    >
                       <FaPencilAlt />
                    </div>
                  ) : (
                    <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}> <FaPencilAlt /></div>
                  )}
                </div>
              )}
            </div>
          </div>
         
          <div className={classes.formGroup}>
            <label>Result sended at</label>
            <div>
              {currentAplication.resultIsSendedDateTime ? (
                new Date(currentAplication.resultIsSendedDateTime).toLocaleString()
              ) : (
                <div className={classes.editDiv}>
                  not sended{" "}
                  {aplication?.patients
                    ?.filter((patient) => patient.aplicationId === aplication.id)
                    .forEach((patient) => {
                      if (!patient.result) {
                        resultsIsSet = false;
                      }
                    })}
                  {resultsIsSet ? (
                    <div
                      className={`${classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(aplication.id);
                        setSettings({
                          message: "sendResults",
                          type: "Yes/Cancel",
                          setPopup: setPopup,
                          fc: sendResults,
                          arg: aplication.id,
                        });
                      }}
                    >
                      Send
                    </div>
                  ) : (
                    <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>Send</div>
                  )}
                </div>
              )}
            </div>
                  </div>*/}
          <div className={classes.formGroup}>
            <Button text={"戻る"} link={searchParams.get("patient") ? "/patients" : "/"} />
          </div>
        </form>
      </div>
      <div className={classes.right}>
        {aplication?.patients?.map((patient) => {
          return (
            <div className={classes.patientCard}>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>検査ID</div>
                <div className={classes.infoItemValue}>{patient.id}</div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>性</div>
                <div className={classes.editDiv}>
                  <div className={classes.infoItemValue}>{patient.gender}</div>
                  <div
                    className={`${patient.gender ? classes.editBtn : classes.btn} ${classes.alt}`}
                    onClick={() => {
                      setPopup(patient.id);
                      setSettings({
                        value: patient.gender || "男性",
                        message: "性別",
                        type: "gender",
                        setPopup: setPopup,
                        fc: onSetPatientGender,
                        arg: patient.id,
                        label: "性別:",
                      });
                    }}
                  >
                    {patient.gender ? <FaPencilAlt /> : "入力"}
                  </div>
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>生年月日</div>
                <div className={classes.editDiv}>
                  <div>{patient.birthdate}</div>
                  <div
                    className={`${patient.birthdate ? classes.editBtn : classes.btn} ${classes.alt}`}
                    onClick={() => {
                      setPopup(patient.id);
                      setSettings({
                        value: () => {
                          let date = new Date(patient.birthdate?.replace("年", "-").replace("月", "-").slice(0, -1));
                          let year = date.getFullYear();
                          let month = (date.getMonth() + 1).toString().padStart(2, "0");
                          let day = date.getDate().toString().padStart(2, "0");
                          return `${year}-${month}-${day}`;
                        },
                        message: "生年月日",
                        type: "input",
                        setPopup: setPopup,
                        fc: onSetPatientBirthdate,
                        arg: patient.id,
                        label: "生年月日:",
                        inputType: "date",
                      });
                    }}
                  >
                    {patient.birthdate ? <FaPencilAlt /> : "入力"}
                  </div>
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>検査者</div>
                <div className={classes.editDiv}>
                  <div className={classes.infoItemValue}>{patient.name}</div>
                  <div
                    className={`${patient.name ? classes.editBtn : classes.btn} ${classes.alt}`}
                    onClick={() => {
                      setPopup(patient.id);
                      setSettings({
                        value: patient.name.slice(0,patient.name.indexOf("(")),
                        value2:patient.name.slice(patient.name.indexOf("(")+1,-1),
                        message: "検査者",
                        type: "name",
                        setPopup: setPopup,
                        fc: onSetPatientName,
                        arg: patient.id,
                        label: "お名前:",
                        label2: "フリガナ:",
                      });
                    }}
                  >
                    {patient.name ? <FaPencilAlt /> : "入力"}
                  </div>
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>検査項目 </div>
                <div className={classes.infoItemValue}>{patient.product}</div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>HIV</div>
                <div className={classes.infoItemValue}>
                  {patient.resultHiv ? (
                    <div>
                      {patient.resultHiv} ({patient.resultHivDateTime})
                    </div>
                  ) : aplication?.kitIsSendedDateTime &&
                    (patient.product === "HIV" || patient.product === "HIV・梅毒セット") ? (
                    <div
                      className={`${classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(patient.id);
                        setSettings({
                          message: "HIV",
                          type: "setResultHiv",
                          setPopup: setPopup,
                          fc: onSetResultHiv,
                          arg: patient.id,
                        });
                      }}
                    >
                      入力
                    </div>
                  ) : (
                    <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>無</div>
                  )}
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>梅毒</div>
                <div className={classes.infoItemValue}>
                  {patient.resultSyp ? (
                    <div>
                      {patient.resultSyp} ({patient.resultSypDateTime})
                    </div>
                  ) : aplication?.kitIsSendedDateTime &&
                    (patient.product === "梅毒" || patient.product === "HIV・梅毒セット") ? (
                    <div
                      className={`${classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(patient.id);
                        setSettings({
                          message: "梅毒",
                          type: "setResultSyp",
                          setPopup: setPopup,
                          fc: onSetResultSyp,
                          arg: patient.id,
                        });
                      }}
                    >
                      入力
                    </div>
                  ) : (
                    <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>無</div>
                  )}
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>PDFパス</div>
                <div className={classes.infoItemValue}>
                  <div>
                    {patient.pdfPassword ? (
                      <div className={classes.editedField}>
                        {patient.pdfPassword}
                        <div
                          className={`${classes.editBtn} ${classes.alt}`}
                          onClick={() => {
                            setPopup(patient.id);
                            setSettings({
                              value: patient.pdfPassword,
                              message: "pdfPassword",
                              type: "input",
                              setPopup: setPopup,
                              fc: onSetPdfPassword,
                              arg: patient.id,
                              label: "pdfPassword",
                              inputType:"number"
                            });
                          }}
                        >
                          <FaPencilAlt />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${classes.btn} ${classes.alt}`}
                        onClick={() => {
                          setPopup(patient.id);
                          setSettings({
                            value: patient.pdfPassword,
                            message: "",
                            type: "input",
                            setPopup: setPopup,
                            fc: onSetPdfPassword,
                            arg: patient.id,
                            label: "PDFパス",
                            inputType:"number"
                          });
                        }}
                      >
                        入力
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.dubbleInfoItem}>
                {patient.product === "HIV" || patient.product === "HIV・梅毒セット" ? (
                  <div className={classes.infoItem}>
                    <div className={classes.infoItemTitle}>通知コメントHIV</div>
                    <div className={classes.infoItemValue}>
                      <div className={classes.editedField}>
                        {patient.commentHiv?.length ? patient.commentHiv.slice(0, 10) + "..." : ""}
                        <div
                          className={`${patient.commentHiv ? classes.editBtn : classes.btn} ${classes.alt}`}
                          onClick={() => {
                            setPopup(patient.id);
                            setSettings({
                              message: "通知コメントHIV",
                              type: "textarea",
                              setPopup: setPopup,
                              fc: onSetCommentHiv,
                              arg: patient.id,
                              label: "",
                              value: patient.commentHiv,
                            });
                          }}
                        >
                          {patient.commentHiv ? <FaPencilAlt /> : "入力"}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {patient.product === "梅毒" || patient.product === "HIV・梅毒セット" ? (
                  <div className={classes.infoItem}>
                    <div className={classes.infoItemTitle}>通知コメント梅毒</div>
                    <div className={classes.infoItemValue}>
                      <div className={classes.editedField}>
                        {patient.commentSyp?.length ? patient.commentSyp.slice(0, 10) + "..." : ""}
                        <div
                          className={`${patient.commentSyp ? classes.editBtn : classes.btn} ${classes.alt}`}
                          onClick={() => {
                            setPopup(patient.id);
                            setSettings({
                              message: "通知コメント梅毒",
                              type: "textarea",
                              setPopup: setPopup,
                              fc: onSetCommentSyp,
                              arg: patient.id,
                              label: "",
                              value: patient.commentSyp,
                            });
                          }}
                        >
                          {patient.commentSyp ? <FaPencilAlt /> : "入力"}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>内部メモ</div>
                <div className={classes.infoItemValue}>
                  <div className={classes.editedField}>
                    {patient.memo?.length ? patient.memo.slice(0, 10) + "..." : ""}
                    <div
                      className={`${patient.memo ? classes.editBtn : classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(patient.id);
                        setSettings({
                          message: "内部メモ",
                          type: "textarea",
                          setPopup: setPopup,
                          fc: onSetMemo,
                          arg: patient.id,
                          label: "",
                          value: patient.memo,
                        });
                      }}
                    >
                      {patient.memo ? <FaPencilAlt /> : "入力"}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoItemTitle}>メール結果通知</div>
                <div className={classes.mailBtn}>
                  {patient.mailIsSended ? (
                    <div>{patient.mailIsSended}</div>
                  ) : (
                    <div
                      className={`${classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(aplication.id);
                        setSettings({
                          message: "結果送信します",
                          type: "Yes/Cancel",
                          setPopup: setPopup,
                          fc: onSendResults,
                          arg: patient.id,
                        });
                      }}
                    >
                      メール送信
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.patientsBtns}>
                <div className={classes.sealBtn}>
                  <div
                    className={`${classes.btn} ${classes.alt} ${printBtnDisable ? classes.gray : ""}`}
                    onClick={() => {
                      if (!printBtnDisable) {
                        sealPrint(patient);
                      }
                    }}
                  >
                    シール 印刷
                  </div>
                </div>
                {/*<div className={classes.mailBtn}>
                  {patient.mailIsSended ? (
                    <div>
                      {patient.mailIsSended.split(" ").map((text) => (
                        <div>{text}</div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className={`${classes.btn} ${classes.alt}`}
                      onClick={() => {
                        setPopup(aplication.id);
                        setSettings({
                          message: "sendResults",
                          type: "Yes/Cancel",
                          setPopup: setPopup,
                          fc: onSendResults,
                          arg: patient.id,
                        });
                      }}
                    >
                      結果メール送信
                    </div>
                  )}
                    </div>*/}
              </div>
            </div>
          );
        })}
      </div>
      {popup ? <Popup settings={settings} /> : ""}
      {wherePopup ? (
        <SendAddressPopup
          postWhere={currentAplication.postWhere}
          postWhereName={currentAplication.postWhereName}
          postWhereAddress={currentAplication.postWhereAddress}
          address={currentAplication.address}
          setWherePopup={setWherePopup}
          onSetPostAddress={onSetPostAddress}
        />
      ) : (
        ""
      )}
    </div>
  );
}
