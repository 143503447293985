import { useState } from "react";
import classes from "./SendAddressPopup.module.scss";

export default function SendAddressPopup({
  setWherePopup,
  postWhere,
  postWhereName,
  postWhereAddress,
  onSetPostAddress,
  address,
}) {
  const [where, setWhere] = useState(postWhere);
  const [whereName, setWhereName] = useState(postWhereName);
  const [whereAddress, setWhereAddress] = useState(postWhereAddress);
  return (
    <div className={classes.wrapper}>
      <div className={`${classes.popup}`}>
      <div className={`${classes.title}`}>
          
        </div>
        <div className={classes.formGroup}>
          <label>配送先選択</label>
          <select
            value={where}
            onChange={(e) => {
              setWhere(e.target.value);
              if (e.target.value === "指定住所") {
                setWhereAddress(address);
              } else {
                setWhereAddress("");
                setWhereName("");
              }
            }}
          >
            <option value="指定住所">指定住所</option>
            <option value="郵便局留め">郵便局留め</option>
            {/*<option value="ヤマトセンター止め">ヤマトセンター止め</option>*/}
          </select>
        </div>
        {where === "郵便局留め" ? (
          <>
            <div className={classes.formGroup}>
              <label>
                <span className={`${classes.requireInput} ${classes.withLinks}`} id="配送先名を">
                  <div>
                    郵便局名 <br />
                    <a target="_blank" rel="noreferrer" href="https://map.japanpost.jp/p/search/">
                      《郵便局検索》
                    </a>
                  </div>
                </span>
              </label>
              <div className={`${classes.withLinks} ${classes.input}`}>
                <input
                  type="text"
                  name="postWhereName"
                  value={`${whereName || ""}`}
                  onChange={(e) => {
                    setWhereName(e.target.value);
                  }}
                  placeholder="郵便局名"
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <label>
                <span className={classes.requireInput} id="配送先住所を">
                  郵便局住所
                </span>
              </label>
              <input
                type="text"
                name="postWhereAddress"
                value={`${whereAddress || ""}`}
                onChange={(e) => {
                  setWhereAddress(e.target.value);
                }}
                placeholder="郵便局住所"
              />
            </div>
          </>
        ) : where === "ヤマトセンター止め" ? (
          <>
            <div className={classes.formGroup}>
              <label>
                <span className={`${classes.requireInput} ${classes.withLinks}`} id="配送先名を">
                  <div>
                    センター名 <br />
                    <a target="_blank" rel="noreferrer" href="http://www.e-map.ne.jp/p/yamato01/">
                      《センター検索》
                    </a>
                  </div>
                </span>
              </label>
              <div className={`${classes.withLinks} ${classes.input}`}>
                <input
                  type="text"
                  name="postWhereName"
                  value={`${whereName || ""}`}
                  onChange={(e) => {
                    setWhereName(e.target.value);
                  }}
                  placeholder="センター名"
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <label>
                <span className={classes.requireInput} id="配送先住所を">
                  センター住所
                </span>
              </label>
              <input
                type="text"
                name="postWhereAddress"
                value={`${whereAddress || ""}`}
                onChange={(e) => {
                  setWhereAddress(e.target.value);
                }}
                placeholder="センター住所"
              />
            </div>
          </>
        ) : (
          <div className={classes.formGroup}>
            <label>届け先</label>
            <div>{address}</div>
          </div>
        )}
        <div className={classes.formGroup}>
          <div
            className={`btn`}
            onClick={() => {
              onSetPostAddress(where, whereName, whereAddress);
              setWherePopup(false);
            }}
          >
            OK
          </div>
          <div
            className={`btn`}
            onClick={() => {
              setWherePopup(false);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
}
