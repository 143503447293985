import { useState, useContext } from "react";
import classes from "./AuthPage.module.scss";
import { Context } from "../../Store/context";
import logo from "./assets/logo.png"

export default function AuthPage() {
  const { LogIn, loginError } = useContext(Context);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const onAuth = (e) => {
    e.preventDefault();
    LogIn(userId, password);
  };
  return (
    <div className={classes.AuthPage}>
      <img className={classes.logo} src={logo} alt="logo" width="20%" />
      <div className={classes.version}>V2024.02.13</div>
      <form onSubmit={onAuth}>
        <div className={classes.wrapper}>
          <div className={classes.loginError}>{loginError}</div>
          <input
            type="text"
            placeholder="userId"
            value={userId}
            required
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
          <input
            type="password"
            required
            placeholder="パスワード"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button className={classes.btn}>ログイン</button>
        </div>
      </form>
    </div>
  );
}
