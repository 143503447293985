import { useContext, useEffect, useRef, useState } from "react";
import classes from "./Aplications.module.scss";
import { Context } from "../../../Store/context";
import Button from "../../../components/Button/Button";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint/ComponentToPrint";
import Popup from "../../../components/Popup/Popup";

export default function Aplications() {
  const { aplications, fetchAplications, patients, fetchPatients, sendKit, setArrivalDate, sendResults } = useContext(
    Context
  );
  useEffect(() => {
    fetchAplications();
    fetchPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let colsNames = ["No", "注文日", "注文ID", "申込者", "検査数","配送先", "配送先住所", "支払い方法", "支払済", "発送"];
  let colsValueNames = [
    "No",
    "createdAt",
    "id",
    "name",
    "patients",
    "postWhere",
    "postWhereAddress",
    "payMethod",
    "isPaid",
    "kitIsSendedDateTime",
  ];
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const componentRef4 = useRef();
  const componentRef5 = useRef();
  const componentRef6 = useRef();
  const componentRef7 = useRef();
  const componentRef8 = useRef();
  const componentRef9 = useRef();
  const componentRef10 = useRef();
  const componentRef11 = useRef();
  const componentRef12 = useRef();
  const componentRef13 = useRef();
  const componentRef14 = useRef();
  const componentRef15 = useRef();
  const componentRef16 = useRef();
  const componentRef17 = useRef();
  const componentRef18 = useRef();
  const componentRef19 = useRef();
  const componentRef20 = useRef();
  const refs = {
    componentRef1,
    componentRef2,
    componentRef3,
    componentRef4,
    componentRef5,
    componentRef6,
    componentRef7,
    componentRef8,
    componentRef9,
    componentRef10,
    componentRef11,
    componentRef12,
    componentRef13,
    componentRef14,
    componentRef15,
    componentRef16,
    componentRef17,
    componentRef18,
    componentRef19,
    componentRef20,
  };
  const [printBtnDisable, setPrintBtnDisable] = useState(false);
  const sealPrint = (patients) => {
    setPrintBtnDisable(true);
    setTimeout(() => {
      setPrintBtnDisable(false);
    }, 3000);
    patients.forEach((patient, index) => {
      let testDateTime = new Date();
      //testDateTime.setHours(testDateTime.getHours() - 9);
      patient = {
        ...patient,
        testDate: testDateTime.toLocaleDateString(),
        testTime: testDateTime.toLocaleTimeString().slice(0, -3),
      };
      setTimeout(() => {
        // call your function here for each element
        window.DoPrint(patient);
      }, 6000 * index); // delay increases with each index
    });
  };
  const [popup, setPopup] = useState(false);
  const [settings, setSettings] = useState({});
  const [page, setPage] = useState(1);
  const [appsPerPage, setAppsPerPage] = useState(10);
  const [isSended, setIsSended] = useState(true);
  const [order, setOrder] = useState("ASK");
  const [payMethod, setPayMethod] = useState("");
  const [appDate, setAppDate] = useState("");
  const [name, setName] = useState("");
  //console.log(appDate);
  console.log(aplications.filter(el=>el.payMethod ==="コンビニ収納（前払い）"));
  return (
    <div className={classes.aplications}>
      <div className={`${classes.tableHeader}`}>
        <div></div>
        <div className={classes.tableTitle}>発送予定者リスト</div>
        <div className={classes.filters}>
          <input
            type="text"
            placeholder="申込者"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setPage(1);
            }}
          />
          <div>
            注文日：
            <input
              type="date"
              value={appDate}
              onChange={(e) => {
                setAppDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <select
            value={payMethod}
            onChange={(e) => {
              setPayMethod(e.target.value);
              setPage(1);
            }}
          >
            <option value="">支払い</option>
            <option value="クレジットカード">クレジットカード</option>
            <option value="コンビニ収納（前払い）">コンビニ</option>
            <option value="LINE Pay">LINE Pay</option>
            <option value="au PAY">au PAY</option>
            <option value="PayPay">PayPay</option>
          </select>
          <div>
            <label htmlFor="">未発送</label>
            <input
              type="checkbox"
              checked={isSended}
              onChange={() => {
                setIsSended(!isSended);
                setPage(1);
              }}
            />
          </div>
          <select
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          >
            <option value="ASK">昇順</option>
            <option value="DES">降順</option>
          </select>
          <select
            value={appsPerPage}
            onChange={(e) => {
              setAppsPerPage(e.target.value);
              setPage(1);
            }}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div
            className={`${classes.btn} ${classes.alt}`}
            onClick={() => {
              setIsSended(false);
              setOrder("ASK");
              setAppDate("");
              setName("");
              setPayMethod("");
            }}
          >
            フィルタ解除
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <table>
          <tbody>
            <tr>
              {colsNames?.map((col) => (
                <td key={col} className={`${classes.col}`}>
                  {col}
                </td>
              ))}
              <th></th>
            </tr>
            {[...aplications]
              ?.sort((a, b) => (order === "ASK" ? a.id - b.id : b.id - a.id))
              .filter((el) => {
                if (isSended) {
                  let today = new Date();
                  let kitIsSendedDateTime = new Date();
                  kitIsSendedDateTime.setTime(Date.parse(el.kitIsSendedDateTime));
                  if (
                    !el.kitIsSendedDateTime ||
                    today.toLocaleDateString() === kitIsSendedDateTime.toLocaleDateString()
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }
                return true;
              })
              .filter((el) => el.payMethod === payMethod || payMethod === "")
              .filter((el) => {
                let tempDate = new Date();
                tempDate.setTime(Date.parse(appDate));
                let appDateTemp = new Date();
                appDateTemp.setTime(Date.parse(el.createdAt));
                if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                  return true;
                }
                return false;
              })
              .filter((el) => el.name.includes(name))
              .slice((page - 1) * appsPerPage, page * appsPerPage)
              .map((aplication, i) => {
                let resulsIsSet = true;
                patients
                  .filter((patient) => patient.aplicationId === aplication.id)
                  .forEach((patient) => {
                    if (!patient.result) {
                      resulsIsSet = false;
                    }
                  });
                return (
                  <tr key={aplication.id}>
                    {colsValueNames?.map((col) => {
                      return (
                        <td className={`${classes.col}`} key={col}>
                          {{
                            No: (i + 1).toString(),
                            name: (
                              <div>
                                {aplication.name
                                  .slice(0, -1)
                                  .split("(")
                                  .reverse()
                                  .map((el, i) => (
                                    <div className={i === 0 ? classes.small : ""}>{el}</div>
                                  ))}
                              </div>
                            ),
                            patients: (
                              <div>
                                {/* {patients.filter((p) => p.aplicationId === aplication.id).length}{"検査："} */}
                                {patients
                                  .filter((p) => p.aplicationId === aplication.id)
                                  .map((pat, i) => (
                                    <span>{pat.product} {i+1 === patients.filter((p) => p.aplicationId === aplication.id).length?"":"／"}</span>
                                  ))}
                              </div>
                            ),
                            postWhere:(<div>{aplication[col]==="郵便局留め"?aplication.postWhereName:""}</div>),
                            isPaid:(<div>{aplication[col]?"済":""}</div>),
                            createdAt: new Date(aplication[col]).toLocaleString(),
                            updatedAt: new Date(aplication[col]).toLocaleString(),
                            "delivery slip": (
                              <>
                                <ReactToPrint
                                  trigger={() => <div className={`btn ${classes.btn} ${classes.alt}`}>印刷</div>}
                                  content={() => refs[`componentRef${i + 1}`].current}
                                />
                                <div className={classes.dn}>
                                  <ComponentToPrint
                                    ref={refs[`componentRef${i + 1}`]}
                                    name={aplication.name}
                                    patients={patients.filter((patient) => patient.aplicationId === aplication.id)}
                                  />
                                </div>
                              </>
                            ),
                            seal: (
                              <div
                                className={`${classes.btn} ${classes.alt} ${printBtnDisable ? classes.gray : ""}`}
                                onClick={() => {
                                  if (!printBtnDisable) {
                                    sealPrint(patients.filter((patient) => patient.aplicationId === aplication.id));
                                  }
                                }}
                              >
                                印刷
                              </div>
                            ),
                            kitIsSendedDateTime: aplication[col] ? (
                              new Date(aplication[col]).toLocaleString()
                            ) : (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(aplication.id);
                                  setSettings({
                                    message: "発送済みにしますか？",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: sendKit,
                                    arg: aplication.id,
                                  });
                                }}
                              >
                                発送処理
                              </div>
                            ),
                            estimatedArrivalDateTime: aplication[col] ? (
                              new Date(aplication[col]).toLocaleDateString()
                            ) : aplication["kitIsSendedDateTime"] ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(aplication.id);
                                  setSettings({
                                    message: "estimatedArrivalDateTime",
                                    type: "InputDate",
                                    setPopup: setPopup,
                                    fc: setArrivalDate,
                                    arg: aplication.id,
                                  });
                                }}
                              >
                                入力
                              </div>
                            ) : (
                              <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>修正</div>
                            ),
                            resultIsSendedDateTime: aplication[col] ? (
                              new Date(aplication[col]).toLocaleDateString()
                            ) : resulsIsSet ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(aplication.id);
                                  setSettings({
                                    message: "sendResults",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: sendResults,
                                    arg: aplication.id,
                                  });
                                }}
                              >
                                Send
                              </div>
                            ) : (
                              <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>Send</div>
                            ),
                            active: aplication[col] ? "" : "無効",
                          }[col] || aplication[col]}
                        </td>
                      );
                    })}
                    <td className={classes.btnField}>
                      <Button className={classes.btn} text={"修正"} link={"/aplication/" + aplication.id} />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {Math.ceil(
          aplications
            .filter((el) => {
              if (isSended) {
                let today = new Date();
                let kitIsSendedDateTime = new Date();
                kitIsSendedDateTime.setTime(Date.parse(el.kitIsSendedDateTime));
                if (
                  !el.kitIsSendedDateTime ||
                  today.toLocaleDateString() === kitIsSendedDateTime.toLocaleDateString()
                ) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            })
            .filter((el) => el.payMethod === payMethod || payMethod === "")
            .filter((el) => {
              let tempDate = new Date();
              tempDate.setTime(Date.parse(appDate));
              let appDateTemp = new Date();
              appDateTemp.setTime(Date.parse(el.createdAt));
              if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                return true;
              }
              return false;
            })
            .filter((el) => el.name.includes(name)).length / appsPerPage
        ) > 1 ? (
          <div className={classes.paginationBtns}>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(page > 1 ? page - 1 : page);
              }}
            >
              perv
            </div>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(
                  page <
                    Math.ceil(
                      aplications
                        .filter((el) => {
                          if (isSended) {
                            let today = new Date();
                            let kitIsSendedDateTime = new Date();
                            kitIsSendedDateTime.setTime(Date.parse(el.kitIsSendedDateTime));
                            if (
                              !el.kitIsSendedDateTime ||
                              today.toLocaleDateString() === kitIsSendedDateTime.toLocaleDateString()
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                          return true;
                        })
                        .filter((el) => el.payMethod === payMethod || payMethod === "")
                        .filter((el) => {
                          let tempDate = new Date();
                          tempDate.setTime(Date.parse(appDate));
                          let appDateTemp = new Date();
                          appDateTemp.setTime(Date.parse(el.createdAt));
                          if (
                            appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() ||
                            appDate.length === 0
                          ) {
                            return true;
                          }
                          return false;
                        })
                        .filter((el) => el.name.includes(name)).length / appsPerPage
                    )
                    ? page + 1
                    : page
                );
              }}
            >
              next
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {popup ? <Popup settings={settings} /> : ""}
    </div>
  );
}
