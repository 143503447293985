import { useContext, useEffect } from "react";
import { Context } from "../../../../Store/context";
import classes from "./PDFViewer.module.scss";

function PDFViewer({id, aplication }) {
  const { createPdfPreview, pdf } = useContext(Context);
  useEffect(() => {
    createPdfPreview(id, aplication);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.pdf}>
      <iframe src={pdf} frameBorder="0" title={"result.pdf"}></iframe>
    </div>
  );
}

export default PDFViewer;
