import { NavLink } from "react-router-dom";
import classes from "./Button.module.scss";

export default function Button({ text, link, fc, fcArg, btn }) {
  if (btn) {
    return <button className={`${classes.btn} btn ${classes.alt} ${classes.btnBtn}`}>{text}</button>;
  }
  return (
    <div className={`${classes.btn} btn ${classes.alt}`}>
      {link ? (
        <NavLink to={link}>{text}</NavLink>
      ) : (
        <div
          onClick={() => {
            fc(fcArg);
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
}
