import { useContext, useEffect, useRef, useState } from "react";
import classes from "./Reference.module.scss";
import { Context } from "../../../Store/context";
import Button from "../../../components/Button/Button";
import Popup from "../../../components/Popup/Popup";
import { FaPencilAlt } from "react-icons/fa";

export default function Reference() {
  const {
    aplications,
    fetchAplications,
    patients,
    fetchPatients,
    sendKit,
    sendResults,
    setResultHiv,
    setResultSyp,
    setComment,
    setMemo,
    setPdfPassword,
    setSampleTakenDate,
    setIsKitCameBack,
    setInspectionDate,
  } = useContext(Context);
  useEffect(() => {
    fetchPatients();
    fetchAplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let colsNames = [
    "No",
    "注文日/結果メール送信日",
    "注文ID",
    "検査者ID",
    "検査者",
    "PDFパス",
    "HIV",
    "梅毒",
    "内部メモ",
  ];
  let colsValueNames = [
    "No",
    "createdAt",
    "aplicationId",
    "id",
    "name",
    "pdfPassword",
    "resultHiv",
    "resultSyp",
    "memo",
  ];
  const [popup, setPopup] = useState(false);
  const [settings, setSettings] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [order, setOrder] = useState("ASK");
  const [name, setName] = useState("");
  const [aplicationId, setAplicationId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [appDate, setAppDate] = useState("");
  const [isSended, setIsSended] = useState("");
  const onSetSampleTakenDate = (id, date) => {
    setSampleTakenDate(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetIsKitCameBack = (id, date) => {
    setIsKitCameBack(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetInspectionDate = (id, date) => {
    setInspectionDate(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetPdfPassword = (id, password) => {
    setPdfPassword(id, password, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetResultHiv = (id, result) => {
    setResultHiv(id, result, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetResultSyp = (id, result) => {
    setResultSyp(id, result, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetComment = (id, comment) => {
    setComment(id, comment, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetMemo = (id, memo) => {
    setMemo(id, memo, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSendResults = (id) => {
    sendResults(id, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  //console.log(patients);
  return (
    <div className={classes.aplications}>
      <div className={classes.PrintPatientsByDate}></div>
      <div className={`${classes.tableHeader}`}>
        <div></div>
        <div className={classes.tableTitle}>検査結果</div>
        <div className={classes.filters}>
          <input
            type="text"
            placeholder="検査者"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setPage(1);
            }}
          />
          <div>
            <label htmlFor="">注文:</label>
            <input
              type="date"
              value={appDate}
              onChange={(e) => {
                setAppDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <select
            name=""
            id=""
            value={isSended}
            onChange={(e) => {
              setIsSended(e.target.value);
            }}
          >
            <option value="">All</option>
            <option value="notIsSended">notIsSended</option>
            <option value="isSended">isSended</option>
          </select>
          <input
            type="number"
            placeholder="注文ID"
            value={aplicationId}
            onChange={(e) => {
              setAplicationId(e.target.value);
              setPage(1);
            }}
          />
          <input
            type="number"
            placeholder="検査者ID"
            value={patientId}
            onChange={(e) => {
              setPatientId(e.target.value);
              setPage(1);
            }}
          />
          <select
            name=""
            id=""
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          >
            <option value="ASK">昇順</option>
            <option value="DES">降順</option>
          </select>
          <select
            name=""
            id=""
            value={perPage}
            onChange={(e) => {
              setPerPage(e.target.value);
              setPage(1);
            }}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div
            className={`${classes.btn} ${classes.alt}`}
            onClick={() => {
              setOrder("ASK");
              setName("");
              setAplicationId("");
              setPatientId("");
              setAppDate("");
            }}
          >
            フィルタ解除
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <table>
          <tbody>
            <tr>
              {colsNames?.map((col) => (
                <td
                  key={col}
                  className={`${classes.col}`}
                  style={col === "注文日/結果メール送信日" ? { width: "350px" } : {}}
                >
                  {col}
                </td>
              ))}
              <th></th>
            </tr>
            {[...patients]
              ?.sort((a, b) => (order === "ASK" ? a.id - b.id : b.id - a.id))
              .filter((el) => (el.name ? el.name?.includes(name) : true))
              .filter((el) => !aplicationId || +el.aplicationId === +aplicationId)
              .filter((el) => !patientId || +el.id === +patientId)
              .filter((el) => {
                let tempDate = new Date();
                tempDate.setTime(Date.parse(appDate));
                let appDateTemp = new Date();
                appDateTemp.setTime(Date.parse(el.createdAt));
                if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                  return true;
                }
                return false;
              })
              .filter(
                (el) =>
                  isSended === "" ||
                  (isSended === "notIsSended" && !el.mailIsSended) ||
                  (isSended === "isSended" && el.mailIsSended)
              )
              .slice((page - 1) * perPage, page * perPage)
              .map((patient, i) => {
                //console.log(patient);
                let resultsIsSet =
                  patient.product === "HIV"
                    ? patient.resultHiv
                    : patient.product === "梅毒"
                    ? patient.resultSyp
                    : patient.resultHiv && patient.resultSyp;
                let aplication = aplications.filter((app) => patient.aplicationId === app.id)[0];
                return (
                  <tr key={patient.id}>
                    {colsValueNames?.map((col) => {
                      return (
                        <td className={`${classes.col}`} key={col}>
                          {{
                            No: (i + 1).toString(),
                            name: (
                              <div>
                                {patient.name
                                  ?.slice(0, -1)
                                  .split("(")
                                  .reverse()
                                  .map((el, i) => (
                                    <div className={i === 0 ? classes.small : ""}>{el}</div>
                                  ))}
                              </div>
                            ),
                            createdAt: (
                              <div className={classes.orderAndSend}>
                                <div>
                                  注文日: <br />
                                  結果送信日:{" "}
                                </div>
                                <div>
                                  {new Date(patient[col]).toLocaleString()} <br />
                                  {patient.mailIsSended || "not sended"}
                                </div>
                              </div>
                            ),
                            updatedAt: new Date(patient[col])
                              .toLocaleString()
                              .split(" ")
                              .map((text) => <div key={text}>{text}</div>),
                            kitIsSendedDateTime: aplication?.kitIsSendedDateTime ? (
                              new Date(aplication?.kitIsSendedDateTime)
                                .toLocaleString()
                                .split(" ")
                                .map((text) => <div key={text}>{text}</div>)
                            ) : (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(aplication.id);
                                  setSettings({
                                    message: "setSended",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: sendKit,
                                    arg: aplication.id,
                                  });
                                }}
                              >
                                Send
                              </div>
                            ),
                            flow: (
                              <div>
                                <div className={classes.dateGroup}>
                                  <div className={classes.lable}>採取:</div>
                                  {patient.sampleTakenDate || (
                                    <div
                                      className={`${classes.btn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "採取",
                                          value: patient.sampleTakenDate || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetSampleTakenDate,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      修正
                                    </div>
                                  )}
                                </div>
                                <div className={classes.dateGroup}>
                                  <div className={classes.lable}>返却:</div>
                                  {patient.isKitCameBack || (
                                    <div
                                      className={`${classes.btn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "返却",
                                          value: patient.isKitCameBack || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetIsKitCameBack,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      修正
                                    </div>
                                  )}
                                </div>
                                <div className={classes.dateGroup}>
                                  <div className={classes.lable}>検査:</div>
                                  {patient.inspectionDate || (
                                    <div
                                      className={`${classes.btn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "検査",
                                          value: patient.inspectionDate || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetInspectionDate,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      修正
                                    </div>
                                  )}
                                </div>
                              </div>
                            ),
                            pdfPassword: patient.pdfPassword,
                            resultHiv: patient[col] || "",
                            resultSyp: patient[col] || "",
                            comment: (
                              <div className={classes.editedField}>
                                {/*patient[col]?.length? patient[col].split("\n").map(el=><div>{el}</div>):""*/}
                                {patient[col]?.length ? patient[col].slice(0, 10) + "..." : ""}
                                <div
                                  className={`${patient[col] ? classes.editBtn : classes.btn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(patient.id);
                                    setSettings({
                                      message: "通知コメント",
                                      type: "textarea",
                                      setPopup: setPopup,
                                      fc: onSetComment,
                                      arg: patient.id,
                                      label: "",
                                      value: patient.comment,
                                    });
                                  }}
                                >
                                  {patient[col] ? <FaPencilAlt /> : "修正"}
                                </div>
                              </div>
                            ),
                            memo: (
                              <div className={classes.editedField}>
                                {patient[col]?.length ? patient[col].slice(0, 10) + "..." : ""}
                              </div>
                            ),
                            mailIsSended: patient[col] ? (
                              patient[col].length ? (
                                patient[col].split(" ").map((text) => <div key={text}>{text}</div>)
                              ) : (
                                ""
                              )
                            ) : resultsIsSet ? (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(patient.id);
                                  setSettings({
                                    message: "sendResults",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: onSendResults,
                                    arg: patient.id,
                                  });
                                }}
                              >
                                メール送信
                              </div>
                            ) : (
                              <div className={`${classes.btn} ${classes.alt} ${classes.gray}`}>メール送信</div>
                            ),
                          }[col] || patient[col]}
                        </td>
                      );
                    })}
                    <td className={classes.btnField}>
                      <Button
                        className={classes.btn}
                        text={"修正"}
                        link={"/aplication/" + aplication?.id + "?patient=" + patient?.id}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {Math.ceil(patients.filter((el) => (el.name ? el.name?.includes(name) : true)).length / perPage) > 1 ? (
          <div className={classes.paginationBtns}>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(page > 1 ? page - 1 : page);
              }}
            >
              perv
            </div>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(
                  page < Math.ceil(patients.filter((el) => (el.name ? el.name?.includes(name) : true)).length / perPage)
                    ? page + 1
                    : page
                );
              }}
            >
              next
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {popup ? <Popup settings={settings} /> : ""}
    </div>
  );
}
