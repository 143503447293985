import React from "react";
import classes from "./ComponentToPrint.module.scss";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div className={classes.wrapper}>
        検査日：{this.props.printDate}
        <div className={classes.title}>当日検査表</div>
        <table>
          <tr>
            <th rowspan="2"></th>
            <th rowspan="2">ID</th>
            <th rowspan="2">氏名</th>
            <th rowspan="2">採取日</th>
            <th rowspan="2">受付日</th>
            <th rowspan="2">報告日</th>
            <th colspan="2">検査項目</th>
            <th rowspan="2" className={classes.memo}>
              特記事項
            </th>
          </tr>
          <tr>
            <th>HIV</th>
            <th>梅毒</th>
          </tr>

          {this.props.patients.map((p, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{p.id}</td>
              <td>
                {p.name
                  .slice(0, -1)
                  .split("(")
                  .reverse()
                  .map((el, i) => (
                    <div className={i === 0 ? classes.small : ""}>{el}</div>
                  ))}
              </td>
              <td>{p.sampleTakenDate}</td>
              <td> {p.isKitCameBack}</td>
              <td></td>
              <td> {p.product !== "梅毒" || "／"}</td>
              <td> {p.product !== "HIV" || "／"}</td>
              <td></td>
            </tr>
          ))}
        </table>
      </div>
    );
  }
}
