import { useContext } from "react";
import classes from "./Admin.module.scss";
import { Context } from "../../Store/context";
import Aplications from "./Aplications/Aplications";
import Users from "./Users/Users";
import Patients from "./Patients/Patients";
import { NavLink } from "react-router-dom";
import PatientsNotResived from "./PatientsNotResived/PatientsNotResived";
import Reference from "./Reference/Reference";

export default function Admin({ tab }) {
  const { currentUser } = useContext(Context);
  return (
    <div className={classes.Admin}>
      <div className={classes.tabs}>
        <NavLink className={`${classes.tab} ${tab === 1 ? classes.active : ""}`} to={"/"}>
          発送予定者
        </NavLink>
        <NavLink className={`${classes.tab} ${tab === 3 ? classes.active : ""}`} to={"/patientsNotResived"}>
          未返却
        </NavLink>
        <NavLink className={`${classes.tab} ${tab === 2 ? classes.active : ""}`} to={"/patients"}>
          検査結果
        </NavLink>
        <NavLink className={`${classes.tab} ${tab === 4 ? classes.active : ""}`} to={"/reference"}>
        照会
        </NavLink>
        {currentUser.role === 1 ? (
          <NavLink className={`${classes.tab} ${tab === 99 ? classes.active : ""}`} to={"/users"}>
            Users
          </NavLink>
        ) : (
          ""
        )}
      </div>
      <div className={classes.panels}>
        <div className={classes.panel}>
          {
            {
              "1": <Aplications />,
              "2": <Patients />,
              "3": <PatientsNotResived />,
              "4": <Reference />,
              "99": <Users />,
            }[tab]
          }
        </div>
      </div>
    </div>
  );
}
