import { Route, Routes } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { Context } from "./Store/context";
import Message from "./components/Message/Message";
import Header from "./Pages/Admin/Header/Header";
import AuthPage from "./Pages/AuthPage/AuthPage";
import CreateEditUser from "./Pages/Admin/Users/CreateEditUser/CreateEditUser";
import EditAplication from "./Pages/Admin/Aplications/EditAplication/EditAplication";
import Admin from "./Pages/Admin/Admin";

function App() {
  const { auth, check, message,currentUser } = useContext(Context);
  const [isLoadin, setIsLoadin] = useState(true);
  useEffect(() => {
    check().finally(() => setIsLoadin(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      {isLoadin ? (
        <div className="loader">読み込み中....</div>
      ) : (
        <>
          {message.text ? <Message /> : ""}
          {auth ? (
            <div className="wrapper">
              <div className="right">
                <Header />
                <Routes>
                  {currentUser.role === 1 ? (
                    <>
                      <Route exec path={"/users"} element={<Admin tab={99} />} />
                      <Route exec path={"/users/user/:id"} element={<CreateEditUser />} />
                      <Route exec path={"/users/add"} element={<CreateEditUser />} />
                    </>
                  ) : (
                    ""
                  )}
                  <Route exec path="/aplication/:id" element={<EditAplication />} />
                  <Route exec path="/patients" element={<Admin tab={2} />} />
                  <Route exec path="/patientsNotResived" element={<Admin tab={3} />} />
                  <Route exec path="/reference" element={<Admin tab={4} />} />
                  <Route exec path="/*" element={<Admin tab={1} />} />
                </Routes>
              </div>
            </div>
          ) : (
            <Routes>
              <Route exac path="/*" element={<AuthPage />} />
            </Routes>
          )}
        </>
      )}
    </div>
  );
}

export default App;
