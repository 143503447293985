import React from "react";
import classes from "./ComponentToPrint.module.scss";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div className={classes.wrapper}>
        <div className={classes.client}>
          <div className={classes.type}>（お客様控え）</div>
          <div className={classes.title}>納品書　兼　検査申込書</div>
          <div className={classes.name}>{this.props.name.substr(0,this.props.name.indexOf("("))}</div>
          <div className={classes.company}>
            トリエル検査センター
            <br />
            〒370-0036群馬県高崎市南大類町888-1
            <br />
            TEL: 027-386-8684
            <br />
          </div>
          <div className={classes.patients}>
            {this.props.patients?.map((patient) => {
              let name = patient.name.substr(0,patient.name.indexOf("("))
              return (
                <div className={classes.patient} key={patient.id}>
                  <div className={classes.test}>
                    <div className={classes.testNameTitle}>検査項目 :</div>
                    <div className={classes.testName}>{patient.product}</div>
                  </div>
                  <div className={classes.patientName}>{name} 様分</div>
                </div>
              );
            })}
          </div>
          <div className={classes.conclusion}>上記の通り、検査を申し込みます。</div>
          <div className={classes.signature}>署名（自署）</div>
        </div>
      </div>
    );
  }
}
