import { useContext, useEffect, useState } from "react";
import classes from "./PatientsNotResived.module.scss";
import { Context } from "../../../Store/context";
import Popup from "../../../components/Popup/Popup";
import { FaPencilAlt } from "react-icons/fa";

export default function PatientsNotResived() {
  const {
    aplications,
    fetchAplications,
    patients,
    fetchPatients,
    sendKit,
    cancelSendKit,
    setPdfPassword,
    setSampleTakenDate,
    setIsKitCameBack,
    setPatientName,
    setPatientBirthdate,
    setPatientGender,
    createAdPay,
    fetchAdditionalPayments,
    additionalPayments,
    sendAdditionalPaymentMail,
    sendAdditionalKit,
  } = useContext(Context);
  useEffect(() => {
    fetchPatients();
    fetchAplications();
    fetchAdditionalPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let colsNames = [
    "No",
    "注文日",
    "発送",
    "注文ID",
    "検査者ID",
    "検査者",
    "性",
    "生年月日",
    "キット返却日",
    "検体採取日",
    "PDFパス",
    "追加支払い",
    "追加メール送信",
    "支払済",
    "追加キット発送",
  ];
  let colsValueNames = [
    "No",
    "createdAt",
    "kitIsSendedDateTime",
    "aplicationId",
    "id",
    "name",
    "gender",
    "birthdate",
    "isKitCameBack",
    "sampleTakenDate",
    "pdfPassword",
    "AdPay",
    "AdPaySendMail",
    "isPaid",
    "AdPaySendKit",
  ];
  const [popup, setPopup] = useState(false);
  const [settings, setSettings] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [order, setOrder] = useState("ASK");
  const [appDate, setAppDate] = useState("");
  const [name, setName] = useState("");
  const [patientId, setPatientId] = useState("");
  const onSetSampleTakenDate = (id, date) => {
    setSampleTakenDate(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetIsKitCameBack = (id, date) => {
    setIsKitCameBack(id, date, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetPdfPassword = (id, password) => {
    setPdfPassword(id, password, patients.filter((patient) => patient.id === id)[0].aplicationId);
  };
  const onSetPatientName = (id, name, furigana) => {
    name = name + "(" + furigana + ")";
    setPatientName(id, name);
  };
  const onSetPatientBirthdate = (id, birthdate) => {
    let array = birthdate.split("-");
    birthdate = "";
    array.forEach((el, i) => {
      birthdate = birthdate + +el + (i === 0 ? "年" : i === 1 ? "月" : "日");
    });
    setPatientBirthdate(id, birthdate);
  };
  const onSetPatientGender = (id, gender) => {
    setPatientGender(id, gender);
  };
  ///console.log(patients);
  return (
    <div className={classes.aplications}>
      <div className={`${classes.tableHeader}`}>
        <div></div>
        <div className={classes.tableTitle}>未返却リスト</div>
        <div className={classes.filters}>
          <input
            type="number"
            placeholder="検査者ID"
            value={patientId}
            onChange={(e) => {
              setPatientId(e.target.value);
              setPage(1);
            }}
          />
          <div>
            注文日：
            <input
              type="date"
              value={appDate}
              onChange={(e) => {
                setAppDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <select
            name=""
            id=""
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          >
            <option value="ASK">昇順</option>
            <option value="DES">降順</option>
          </select>
          <select
            name=""
            id=""
            value={perPage}
            onChange={(e) => {
              setPerPage(e.target.value);
              setPage(1);
            }}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div
            className={`${classes.btn} ${classes.alt}`}
            onClick={() => {
              setOrder("ASK");
              setAppDate("");
              setName("");
              setPatientId("");
            }}
          >
            フィルタ解除
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <table>
          <tbody>
            <tr>
              {colsNames?.map((col) => (
                <td key={col} className={`${classes.col}`}>
                  {col}
                </td>
              ))}
            </tr>
            {[...patients]
              ?.sort((a, b) => (order === "ASK" ? a.id - b.id : b.id - a.id))
              .filter((el) => {
                if (
                  aplications.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime &&
                  !(
                    el.sampleTakenDate &&
                    el.isKitCameBack &&
                    el.pdfPassword &&
                    el.name &&
                    el.name !== "()" &&
                    el.gender &&
                    el.birthdate
                  )
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .filter((el) => {
                let tempDate = new Date();
                tempDate.setTime(Date.parse(appDate));
                let appDateTemp = new Date();
                appDateTemp.setTime(Date.parse(el.createdAt));
                if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                  return true;
                }
                return false;
              })
              .filter((el) => (el.name ? el.name?.includes(name) : true))
              .filter((el) => (patientId ? el.id === +patientId : true))
              .slice((page - 1) * perPage, page * perPage)
              .map((patient, i) => {
                let aplication = aplications.filter((app) => patient.aplicationId === app.id)[0];
                return (
                  <tr key={patient.id}>
                    {colsValueNames?.map((col) => {
                      return (
                        <td className={`${classes.col}`} key={col}>
                          {{
                            No: (i + 1).toString(),
                            name: (
                              <div className={classes.editDiv}>
                                <div>
                                  {patient.name
                                    ?.replace(")", "")
                                    .split("(")
                                    .reverse()
                                    .map((el, i) => (
                                      <div className={i === 0 ? classes.small : ""}>{el}</div>
                                    ))}
                                </div>
                                <div
                                  className={`${
                                    patient.name && patient.name !== "()" ? classes.editBtn : classes.btn
                                  } ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(patient.id);
                                    setSettings({
                                      value: patient.name?.slice(0, patient.name.indexOf("(")),
                                      value2: patient.name?.slice(patient.name.indexOf("(") + 1, -1),
                                      message: "検査者",
                                      type: "name",
                                      setPopup: setPopup,
                                      fc: onSetPatientName,
                                      arg: patient.id,
                                      label: "お名前:",
                                      label2: "フリガナ:",
                                    });
                                  }}
                                >
                                  {patient.name && patient.name !== "()" ? <FaPencilAlt /> : "入力"}
                                </div>
                              </div>
                            ),
                            gender: (
                              <div className={classes.editDiv}>
                                <div>{patient.gender}</div>
                                <div
                                  className={`${patient.gender ? classes.editBtn : classes.btn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(aplication.id);
                                    setSettings({
                                      value: patient.gender || "男性",
                                      message: "性別",
                                      type: "gender",
                                      setPopup: setPopup,
                                      fc: onSetPatientGender,
                                      arg: patient.id,
                                      label: "性別:",
                                    });
                                  }}
                                >
                                  {patient.gender ? <FaPencilAlt /> : "入力"}
                                </div>
                              </div>
                            ),
                            birthdate: (
                              <div className={classes.editDiv}>
                                {patient.birthdate}
                                <div
                                  className={`${patient.birthdate ? classes.editBtn : classes.btn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(patient.id);
                                    setSettings({
                                      value: () => {
                                        let date = new Date(
                                          patient.birthdate?.replace("年", "-").replace("月", "-").slice(0, -1)
                                        );
                                        let year = date.getFullYear();
                                        let month = (date.getMonth() + 1).toString().padStart(2, "0");
                                        let day = date.getDate().toString().padStart(2, "0");
                                        return `${year}-${month}-${day}`;
                                      },
                                      message: "生年月日",
                                      type: "input",
                                      setPopup: setPopup,
                                      fc: onSetPatientBirthdate,
                                      arg: patient.id,
                                      label: "生年月日:",
                                      inputType: "date",
                                    });
                                  }}
                                >
                                  {patient.birthdate ? <FaPencilAlt /> : "入力"}
                                </div>
                              </div>
                            ),
                            createdAt: new Date(patient[col])
                              .toLocaleString()
                              .split(" ")
                              .map((text) => <div key={text}>{text}</div>),
                            updatedAt: new Date(patient[col])
                              .toLocaleString()
                              .split(" ")
                              .map((text) => <div key={text}>{text}</div>),
                            kitIsSendedDateTime: aplication?.kitIsSendedDateTime ? (
                              <div className={classes.editedField}>
                                {new Date(aplication?.kitIsSendedDateTime)
                                  .toLocaleString()
                                  .split(" ")
                                  .map((text) => (
                                    <div key={text}>{text} </div>
                                  ))}
                                <div
                                  className={`${classes.editBtn} ${classes.alt}`}
                                  onClick={() => {
                                    setPopup(aplication.id);
                                    setSettings({
                                      message: "発送日をクリアします",
                                      type: "Yes/Cancel",
                                      setPopup: setPopup,
                                      fc: cancelSendKit,
                                      arg: aplication.id,
                                    });
                                  }}
                                >
                                  <FaPencilAlt />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  setPopup(aplication.id);
                                  setSettings({
                                    message: "発送済みにしますか？",
                                    type: "Yes/Cancel",
                                    setPopup: setPopup,
                                    fc: sendKit,
                                    arg: aplication.id,
                                  });
                                }}
                              >
                                発送処理
                              </div>
                            ),
                            sampleTakenDate: (
                              <div>
                                {patient.sampleTakenDate ? (
                                  <div className={classes.editedField}>
                                    {patient.sampleTakenDate}
                                    <div
                                      className={`${classes.editBtn} ${classes.alt}`}
                                      onClick={() => {
                                        let language = patient.sampleTakenDate.indexOf("/") !== -1 ? 1 : 0;
                                        let temp = language === 1 ? patient.sampleTakenDate.split("/") : undefined;
                                        console.log(temp);
                                        temp =
                                          language === 1
                                            ? new Date(+temp[0], +temp[1] - 1, +temp[2] + 1).toISOString().split("T")[0]
                                            : undefined;
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "検体採取日",
                                          value: temp || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetSampleTakenDate,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      <FaPencilAlt />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`${classes.btn} ${classes.alt}`}
                                    onClick={() => {
                                      setPopup(aplication.id);
                                      setSettings({
                                        label: "検体採取日",
                                        value: patient.sampleTakenDate || new Date().toISOString().substring(0, 10),
                                        message: "",
                                        type: "InputDate",
                                        setPopup: setPopup,
                                        fc: onSetSampleTakenDate,
                                        arg: patient.id,
                                      });
                                    }}
                                  >
                                    入力
                                  </div>
                                )}
                              </div>
                            ),
                            isKitCameBack: (
                              <div>
                                {patient.isKitCameBack ? (
                                  <div className={classes.editedField}>
                                    {patient.isKitCameBack}
                                    <div
                                      className={`${classes.editBtn} ${classes.alt}`}
                                      onClick={() => {
                                        let language = patient.isKitCameBack.indexOf("/") !== -1 ? 1 : 0;
                                        let temp = language === 1 ? patient.isKitCameBack.split("/") : undefined;
                                        console.log(temp);
                                        temp =
                                          language === 1
                                            ? new Date(+temp[0], +temp[1] - 1, +temp[2] + 1).toISOString().split("T")[0]
                                            : undefined;
                                        setPopup(aplication.id);
                                        setSettings({
                                          label: "キット返却日",
                                          value: temp || new Date().toISOString().substring(0, 10),
                                          message: "",
                                          type: "InputDate",
                                          setPopup: setPopup,
                                          fc: onSetIsKitCameBack,
                                          arg: patient.id,
                                        });
                                      }}
                                    >
                                      <FaPencilAlt />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`${classes.btn} ${classes.alt}`}
                                    onClick={() => {
                                      setPopup(aplication.id);
                                      setSettings({
                                        label: "キット返却日",
                                        value: patient.isKitCameBack || new Date().toISOString().substring(0, 10),
                                        message: "",
                                        type: "InputDate",
                                        setPopup: setPopup,
                                        fc: onSetIsKitCameBack,
                                        arg: patient.id,
                                      });
                                    }}
                                  >
                                    入力
                                  </div>
                                )}
                              </div>
                            ),
                            pdfPassword: (
                              <div>
                                {patient.pdfPassword ? (
                                  <div className={classes.editedField}>
                                    {patient.pdfPassword}
                                    <div
                                      className={`${classes.editBtn} ${classes.alt}`}
                                      onClick={() => {
                                        setPopup(patient.id);
                                        setSettings({
                                          value: patient.pdfPassword,
                                          message: "",
                                          type: "input",
                                          setPopup: setPopup,
                                          fc: onSetPdfPassword,
                                          arg: patient.id,
                                          label: "PDFパス",
                                          inputType: "number",
                                        });
                                      }}
                                    >
                                      <FaPencilAlt />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`${classes.btn} ${classes.alt}`}
                                    onClick={() => {
                                      setPopup(patient.id);
                                      setSettings({
                                        value: patient.pdfPassword,
                                        message: "",
                                        type: "input",
                                        setPopup: setPopup,
                                        fc: onSetPdfPassword,
                                        arg: patient.id,
                                        label: "PDFパス",
                                        inputType: "number",
                                      });
                                    }}
                                  >
                                    入力
                                  </div>
                                )}
                              </div>
                            ),
                            AdPay: additionalPayments.filter((ap) => ap.patientId === patient.id)[0] ? (
                              <div className={`${classes.btn}  ${classes.gray}  ${classes.alt}`}>登録済み</div>
                            ) : (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  createAdPay(patient.id, patient.aplicationId);
                                }}
                              >
                                支払い登録
                              </div>
                            ),
                            AdPaySendMail: !additionalPayments.filter((ap) => ap.patientId === patient.id)[0] ? (
                              <div></div>
                            ) : additionalPayments.filter((ap) => ap.patientId === patient.id)[0]?.mailIsSended ? (
                              new Date(additionalPayments.filter((ap) => ap.patientId === patient.id)[0]?.mailIsSended)
                                .toLocaleString()
                                .split(" ")
                                .map((text) => <div key={text}>{text}</div>)
                            ) : (
                              <div
                                className={`${classes.btn} ${classes.alt}`}
                                onClick={() => {
                                  let adPay = additionalPayments.filter((ap) => ap.patientId === patient.id)[0];
                                  sendAdditionalPaymentMail(adPay.id, adPay.price, patient.aplicationId);
                                }}
                              >
                                送信
                              </div>
                            ),
                            isPaid: <div>{additionalPayments.filter((ap) => ap.patientId === patient.id)[0]?.isPaid ? "済" : ""}</div>,
                            AdPaySendKit:
                              !additionalPayments.filter((ap) => ap.patientId === patient.id)[0] ||
                              !additionalPayments.filter((ap) => ap.patientId === patient.id)[0]?.mailIsSended ? (
                                <div></div>
                              ) : additionalPayments.filter((ap) => ap.patientId === patient.id)[0]
                                  ?.kitIsSendedDateTime ? (
                                new Date(
                                  additionalPayments.filter((ap) => ap.patientId === patient.id)[0]?.kitIsSendedDateTime
                                )
                                  .toLocaleString()
                                  .split(" ")
                                  .map((text) => <div key={text}>{text}</div>)
                              ) : (
                                <div
                                  className={`${classes.btn} ${classes.alt}`}
                                  onClick={() => {
                                    sendAdditionalKit(
                                      additionalPayments.filter((ap) => ap.patientId === patient.id)[0].id
                                    );
                                  }}
                                >
                                  発送
                                </div>
                              ),
                          }[col] || patient[col]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {Math.ceil(
          patients
            .filter((el) => {
              if (
                aplications.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime &&
                !(
                  el.sampleTakenDate &&
                  el.isKitCameBack &&
                  el.pdfPassword &&
                  el.name &&
                  el.name !== "()" &&
                  el.gender &&
                  el.birthdate
                )
              ) {
                return true;
              } else {
                return false;
              }
            })
            .filter((el) => {
              let tempDate = new Date();
              tempDate.setTime(Date.parse(appDate));
              let appDateTemp = new Date();
              appDateTemp.setTime(Date.parse(el.createdAt));
              if (appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() || appDate.length === 0) {
                return true;
              }
              return false;
            })
            .filter((el) => (el.name ? el.name?.includes(name) : true)).length / perPage
        ) > 1 ? (
          <div className={classes.paginationBtns}>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(page > 1 ? page - 1 : page);
              }}
            >
              perv
            </div>
            <div
              className={classes.paginationBtn}
              onClick={() => {
                setPage(
                  page <
                    Math.ceil(
                      patients
                        .filter((el) => {
                          if (
                            aplications.filter((app) => el.aplicationId === app.id)[0]?.kitIsSendedDateTime &&
                            !(
                              el.sampleTakenDate &&
                              el.isKitCameBack &&
                              el.pdfPassword &&
                              el.name &&
                              el.name !== "()" &&
                              el.gender &&
                              el.birthdate
                            )
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .filter((el) => {
                          let tempDate = new Date();
                          tempDate.setTime(Date.parse(appDate));
                          let appDateTemp = new Date();
                          appDateTemp.setTime(Date.parse(el.createdAt));
                          if (
                            appDateTemp.toLocaleDateString() === tempDate.toLocaleDateString() ||
                            appDate.length === 0
                          ) {
                            return true;
                          }
                          return false;
                        })
                        .filter((el) => (el.name ? el.name?.includes(name) : true))
                    )
                    ? page + 1
                    : page
                );
              }}
            >
              next
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {popup ? <Popup settings={settings} /> : ""}
    </div>
  );
}
