import React, { useReducer } from "react";
import { Context } from "./context";
import { reducer } from "./reducer";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  SET_AUTH,
  SET_CURRENT_USER,
  SET_MESSAGE,
  FETCH_USERS,
  FETCH_APLICATIONS,
  FETCH_PATIENTS,
  FETCH_APLICATION,
  FETCH_ADDITIONAL_PAYMENTS,
  FETCH_PDF,
} from "./types";
import { useNavigate } from "react-router-dom";

export const State = ({ children }) => {
  const navigate = useNavigate();
  const server = process.env.REACT_APP_SERVER_ADDRESS;
  const initialState = {
    auth: false,
    currentUser: {},
    message: {},
    users: [],
    aplications: [],
    patients: [],
    currentAplication: {},
    additionalPayments: [],
    pdf:null
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  // Headers
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      defCharset: "utf8",
      defParamCharset: "utf8",
    },
  };

  //Auth
  const check = async () => {
    axios
      .get(`${server}/api/user/auth`, headers)
      .then((res) => {
        dispatch({ type: SET_AUTH, payload: true });
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            id: jwtDecode(res.data.token).id,
            name: jwtDecode(res.data.token).name,
            role: jwtDecode(res.data.token).role,
          },
        });
      })
      .catch((e) => {
        dispatch({ type: SET_AUTH, payload: false });
        let payload = { text: e.response.data.message, type: "error" };
        dispatch({ type: SET_MESSAGE, payload });
      });
  };
  const LogIn = async (userId, password) => {
    axios
      .post(`${server}/api/user/login`, { userId, password }, headers)
      .then((res) => {
        dispatch({ type: SET_AUTH, payload: true });
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            id: jwtDecode(res.data.token).id,
            name: jwtDecode(res.data.token).name,
            role: jwtDecode(res.data.token).role,
          },
        });
      })
      .catch((e) => {
        let payload = { text: "メールアドレスかパスワードが正しくありません", type: "error" };
        dispatch({ type: SET_MESSAGE, payload });
      });
  };
  const logout = () => {
    dispatch({ type: SET_CURRENT_USER, payload: {} });
    dispatch({ type: SET_AUTH, payload: false });
    localStorage.setItem("token", "");
  };
  const setMessage = async (payload) => {
    dispatch({ type: SET_MESSAGE, payload });
  };
  //Users
  const fetchUsers = async () => {
    check();
    axios.get(`${server}/api/user/`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_USERS, payload });
    });
  };
  const createEditUser = async (user) => {
    check();
    axios.post(`${server}/api/user/create`, { ...user }, headers).then((res) => {
      navigate("/users");
    });
  };
  const changePassword = (id, password, setChangePasswordTrigger) => {
    check();
    axios.post(`${server}/api/user/changePassword`, { id, password }, headers).then((res) => {
      setChangePasswordTrigger(false);
    });
  };
  const disableEnable = (id, active) => {
    check();
    axios.post(`${server}/api/user/disableEnable`, { id, active }, headers).then((res) => {
      fetchUsers();
    });
  };
  //Aplications
  const fetchAplications = async () => {
    check();
    axios.get(`${server}/api/aplication/`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_APLICATIONS, payload });
    });
  };
  const sendKit = (id) => {
    axios.post(`${server}/api/aplication/sendKit`, { id }, headers).then((res) => {
      fetchAplications();
      fetchCurrentAplication(id);
    });
  };
  const cancelSendKit = (id) => {
    axios.post(`${server}/api/aplication/cancelSendKit`, { id }, headers).then((res) => {
      fetchAplications();
      fetchCurrentAplication(id);
    });
  };
  const setArrivalDate = (id, date) => {
    axios.post(`${server}/api/aplication/setArrivalDate`, { id, date }, headers).then((res) => {
      fetchAplications();
      fetchCurrentAplication(id);
    });
  };
  //current Aplication
  const fetchCurrentAplication = async (id) => {
    check();
    axios.get(`${server}/api/aplication/${id}`, headers).then((res) => {
      let payload = { ...res.data.CurrentAplication, patients: res.data.Patients };
      dispatch({ type: FETCH_APLICATION, payload });
    });
  };
  const setFrom = async (id, postFrom) => {
    check();
    axios.post(`${server}/api/aplication/setFrom`, { id, postFrom }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setName = async (id, name) => {
    check();
    axios.post(`${server}/api/aplication/setName`, { id, name }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setEmail = async (id, email) => {
    check();
    axios.post(`${server}/api/aplication/setEmail`, { id, email }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setGender = async (id, gender) => {
    check();
    axios.post(`${server}/api/aplication/setGender`, { id, gender }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setBirthdate = async (id, birthdate) => {
    check();
    axios.post(`${server}/api/aplication/setBirthdate`, { id, birthdate }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setPhone = async (id, phone) => {
    check();
    axios.post(`${server}/api/aplication/setPhone`, { id, phone }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setAddress = async (id, address) => {
    check();
    axios.post(`${server}/api/aplication/setAddress`, { id, address }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setPaid = async (id) => {
    check();
    axios.post(`${server}/api/aplication/setPaid`, { id }, headers).then((res) => {
      fetchCurrentAplication(id);
    });
  };
  const setPostAddress = async (id, postWhere, postWhereName, postWhereAddress) => {
    check();
    axios
      .post(`${server}/api/aplication/setPostAddress`, { id, postWhere, postWhereName, postWhereAddress }, headers)
      .then((res) => {
        fetchCurrentAplication(id);
      });
  };
  //Patients
  const fetchPatients = async () => {
    check();
    axios.get(`${server}/api/patient`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_PATIENTS, payload });
    });
  };
  const setResultHiv = async (id, result, comment, aplication) => {
    check();
    axios.post(`${server}/api/patient/setResultHiv`, { id, result, comment }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setResultSyp = async (id, result, comment, aplication) => {
    check();
    axios.post(`${server}/api/patient/setResultSyp`, { id, result, comment }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setSampleTakenDate = (id, date, aplication) => {
    axios.post(`${server}/api/patient/sampleTakenDate`, { id, date }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setIsKitCameBack = (id, date, aplication) => {
    axios.post(`${server}/api/patient/isKitCameBack`, { id, date }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setInspectionDate = (id, date, aplication) => {
    axios.post(`${server}/api/patient/inspectionDate`, { id, date }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setPdfPassword = async (id, password, aplication) => {
    check();
    axios.post(`${server}/api/patient/setPdfPassword`, { id, password }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setCommentHiv = async (id, commentHiv, aplication) => {
    check();
    axios.post(`${server}/api/patient/setCommentHiv`, { id, commentHiv }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setCommentSyp = async (id, commentSyp, aplication) => {
    check();
    axios.post(`${server}/api/patient/setCommentSyp`, { id, commentSyp }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setMemo = async (id, memo, aplication) => {
    check();
    axios.post(`${server}/api/patient/setMemo`, { id, memo }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const sendResults = (id, aplication) => {
    axios.post(`${server}/api/patient/sendResults`, { id, aplication }, headers).then((res) => {
      fetchCurrentAplication(aplication);
      fetchPatients();
    });
  };
  const setPatientName = async (id, name, app) => {
    //console.log(id, name, app)
    check();
    axios.post(`${server}/api/patient/setName`, { id, name }, headers).then((res) => {
      if (app) {
        fetchCurrentAplication(app);
      } else {
        fetchPatients();
      }
    });
  };
  const setPatientGender = async (id, gender, app) => {
    check();
    axios.post(`${server}/api/patient/setGender`, { id, gender }, headers).then((res) => {
      if (app) {
        fetchCurrentAplication(app);
      } else {
        fetchPatients();
      }
    });
  };
  const setPatientBirthdate = async (id, birthdate, app) => {
    check();
    axios.post(`${server}/api/patient/setBirthdate`, { id, birthdate }, headers).then((res) => {
      if (app) {
        fetchCurrentAplication(app);
      } else {
        fetchPatients();
      }
    });
  };
  //current AdditionalPayment
  const createAdPay = async (patientId, aplicationId) => {
    check();
    let userId = state.currentUser.id;
    axios.post(`${server}/api/additionalPayment/create`, { patientId, aplicationId, userId }, headers).then((res) => {
      fetchAdditionalPayments();
    });
  };
  const fetchAdditionalPayments = async () => {
    check();
    axios.get(`${server}/api/additionalPayment`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_ADDITIONAL_PAYMENTS, payload });
    });
  };
  const sendAdditionalPaymentMail = (id, price, aplicationId) => {
    axios.post(`${server}/api/additionalPayment/sendMail`, { id, price, aplicationId }, headers).then((res) => {
      fetchAdditionalPayments();
    });
  };
  const sendAdditionalKit = (id) => {
    axios.post(`${server}/api/additionalPayment/sendKit`, { id }, headers).then((res) => {
      fetchAdditionalPayments();
    });
  };
const createPdfPreview = async(id)=>{
  axios.post(`${server}/api/patient/createPdfPreview`, { id }, headers).then((res) => {
    console.log(res)
    fetchFile()
  });
}
  //Files
  const fetchFile = async () => {
    check();
    axios.get(`${server}/files/result.pdf`, { ...headers, responseType: "arraybuffer" }).then((res) => {
      console.log(res)
      let payload = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
      dispatch({ type: FETCH_PDF, payload });
    });
  };
  return (
    <Context.Provider
      value={{
        check,
        LogIn,
        setMessage,
        logout,
        fetchUsers,
        createEditUser,
        changePassword,
        fetchAplications,
        fetchPatients,
        sendKit,
        cancelSendKit,
        setArrivalDate,
        sendResults,
        fetchCurrentAplication,
        setResultHiv,
        setResultSyp,
        setFrom,
        setAddress,
        setPaid,
        setPostAddress,
        setCommentHiv,
        setCommentSyp,
        setMemo,
        disableEnable,
        setPdfPassword,
        setSampleTakenDate,
        setIsKitCameBack,
        setInspectionDate,
        setName,
        setEmail,
        setGender,
        setBirthdate,
        setPhone,
        setPatientName,
        setPatientGender,
        setPatientBirthdate,
        createAdPay,
        fetchAdditionalPayments,
        sendAdditionalPaymentMail,
        sendAdditionalKit,
        fetchFile,
        createPdfPreview,
        auth: state.auth,
        message: state.message,
        currentUser: state.currentUser,
        users: state.users,
        aplications: state.aplications,
        patients: state.patients,
        currentAplication: state.currentAplication,
        additionalPayments: state.additionalPayments,
        pdf:state.pdf
      }}
    >
      {children}
    </Context.Provider>
  );
};
