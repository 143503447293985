import { useState, useContext } from "react";
import classes from "./Header.module.scss";
import { Context } from "../../../Store/context";

export default function Header() {
  const { logout, currentUser } = useContext(Context);
  return (
    <div className={classes.header}>
      <div className={classes.title}>トリエル STD検査</div>
      <div className={classes.right}>
        <div className={classes.greeting}>
          <div className={classes.date}>{new Date().toLocaleDateString()}</div>
          <div className={classes.name}>{currentUser.name}</div>
        </div>
        <div className={`${classes.btn} btn`} onClick={logout}>
          logout
        </div>
      </div>
    </div>
  );
}
