import { useState, useContext, useEffect } from "react";
import classes from "./CreateEditUser.module.scss";
import { Context } from "../../../../Store/context";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Button/Button";

export default function CreateEditUser() {
  const { users, fetchUsers, createEditUser,changePassword } = useContext(Context);
  const params = useParams();
  const [user, setUser] = useState({});
  const [changePasswordTrigger, setChangePasswordTrigger] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setUser(users.filter((user) => +user.id === +params.id)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  const roles = [
    { name: "管理者", value: 1 },
    { name: "スタッフ", value: 2 },
  ];
  const onSubmit = (event) => {
    event.preventDefault();
    createEditUser(user);
  };
  const onChangePasswordTrigger = () => {
    setChangePasswordTrigger(!changePasswordTrigger);
  };
  const onChangePassword = (event) => {
    event.preventDefault();
    changePassword(user.id, newPassword, setChangePasswordTrigger)
  };
  return (
    <div className={classes.CreateEditUser}>
      <div className={classes.title}>{params.id ? "修正：" : "Create"}</div>
      {changePasswordTrigger ? (
        <form onSubmit={onChangePassword}>
          <div className={classes.formGroup}>
            <label>パスワード</label>
            <input
              type="text"
              value={newPassword}
              required
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <Button text={"更新"} btn={true}/>
            <Button text={"戻る"} fc={onChangePasswordTrigger}  />
          </div>
        </form>
      ) : (
        <form className={classes.form} onSubmit={onSubmit}>
          {params.id ? (
            <div className={classes.formGroup}>
              <label>ID</label>
              <div>{user?.id}</div>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.formGroup}>
            <label>名前</label>
            <input
              required
              type="text"
              value={user?.name}
              onChange={(e) => {
                setUser({ ...user, name: e.target.value });
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <label>ユーザーID</label>
            <input
              type="text"
              value={user?.userId}
              required
              onChange={(e) => {
                setUser({ ...user, userId: e.target.value });
              }}
            />
          </div>
          {params.id ? (
            <div className={classes.formGroup}>
              <label>パスワード</label>
              <Button text={"パスワードを変更"} fc={onChangePasswordTrigger} />
            </div>
          ) : (
            <div className={classes.formGroup}>
              <label>パスワード</label>
              <input
                type="text"
                value={user?.password}
                required
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
              />
            </div>
          )}
          <div className={classes.formGroup}>
            <label>権限</label>
            <select
              value={user?.role}
              required
              onChange={(e) => {
                setUser({ ...user, role: e.target.value });
              }}
            >
              <option value="">権限を選択</option>
              {roles.map((role) => (
                <option value={role.value} key={role.value}>{role.name}</option>
              ))}
            </select>
          </div>
          <div className={classes.formGroup}>
            <Button text={"更新"} fc={onSubmit} btn={true} />
            <Button text={"戻る"} link={"/users"} />
          </div>
        </form>
      )}
    </div>
  );
}
